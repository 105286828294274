<div class="container">
  <ul class="step d-flex flex-nowrap">
    <li
      *ngFor="let _ of [].constructor(numberOfSteps); let i = index"
      [ngClass]="{ active: currentStep === i + 1 }"
      class="step-item">
      <a (click)="canJump && currentStepChange.emit(i + 1)" href="javascript:void(0);"></a>
    </li>
  </ul>
</div>
