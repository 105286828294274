@if (state) {
	<div class="card mb-3 bg-light-subtle">
		<div class="row g-0">
			<div class="col-md-3 d-none d-md-flex justify-content-center">
				<div class="row align-content-center" ngbTooltip="{{ state.state }}" placement="end">
					<i class="state-icon-{{ state?.state | stateCodeToName | stringToKebap }} icon-text-primary display-1"></i>
				</div>
			</div>
			<div class="col-md-6">
				<div class="card-body">
					<h3 class="card-title">
						{{ state.state | stateCodeToName }}
						<span class="badge bg-primary-subtle text-primary">
						@if (useCase === 'register' && state.status === autoRegisterStatuses.REGISTERED) {
							<span class="fw-semibold">#TAX_ID: {{ state?.tax_id }}</span>
						} @else if (useCase === 'filing') {
							<app-amount class="fw-semibold" [amount]="amount"></app-amount>
						}
					</span>
					</h3>
					<p class="m-0">
						<small class="text-muted">
							<i aria-hidden="true" class="mdi mdi-calendar-clock"></i>
							Filing Period:
						</small>
						<small class="">  {{ (state.next_run_period_title ? state.next_run_period_title : 'No period selected') | titlecase }}</small>
					</p>
					<p class="m-0">
						@if (useCase === 'register' && state.status === autoRegisterStatuses.REGISTERED) {
							<small class="text-muted">
								<i aria-hidden="true" class="mdi mdi-calendar-check"></i>
								Date Registered:
							</small>
							<small class="">{{ state.updated_at | date:'MMM dd YYYY' }}</small>
						} @else if (useCase === 'filing') {
							<small class="text-muted">
								<i aria-hidden="true" class="mdi mdi-calendar-range"></i>
								Filing Frequency:
							</small>
							<small class="">
								@if (state?.frequency) {
									{{ state.frequency  | frequencyTransform }}
								}
							</small>
						}
					</p>
					<p class="m-0">
						@if (useCase === 'register' && state.status !== autoRegisterStatuses.REGISTERED) {
							<small class="text-muted">
								<i aria-hidden="true" class="mdi mdi-calendar-today"></i>
								Last Updated:
							</small>
							<small class="">{{ state.updated_at | date:'short' }}</small>
						} @else if (useCase === 'filing') {
							<small class="text-muted">
								<i aria-hidden="true" class="mdi mdi-calendar-plus"></i>
								Next Filing:
							</small>
							<small class="">{{ state.next_run_date ? (state.next_run_date| date:'MMM dd YYYY') : 'None scheduled' }}</small>
						}
					</p>
				</div>
			</div>
			<div class="col-md-3 d-flex align-items-end flex-column">
				<span class="m-3 badge border border-2 user-select-none py-0 text-{{ type }} text-bg-light-{{ type }} border-{{ type }}-subtle">
					{{ state.status | lowercase | snakeToString }}
				</span>

				@if (useCase === 'register') {
					@if (state.status === autoRegisterStatuses.PENDING_PAYMENT) {
						<button (click)="makePayment.emit(state)" class="mt-auto m-3 btn btn-sm btn-outline-primary align-text-bottom"
										data-test-id="register-payment-button">Make Payment
						</button>
					}
				} @else if (useCase === 'filing') {
					@if (state.status !== autoFilingStatuses.PENDING_PAYMENT && state.status !== autoFilingStatuses.PROCESSING) {
						<div ngbDropdown placement="bottom-end" class="mt-auto m-2" role="button">
							<i id="optionsDropdown" class="mdi mdi-settings text-warning" ngbDropdownToggle></i>
							<div ngbDropdownMenu>
								<button ngbDropdownItem (click)="cancelAutofiling.emit(state)" data-test-id="cancel-button">
									<i class="mdi mdi-close-circle"></i>
									Cancel
								</button>
								
								@if(canSuspendAutofiling){
									<button ngbDropdownItem data-test-id="suspend-button"> 
										<i class="mdi mdi-pause-circle"></i>
										Suspend
									</button>
								}
								
							</div>
						</div>
					} @else if (state.status === autoFilingStatuses.PENDING_PAYMENT) {
						<button (click)="makePayment.emit(state)" class="mt-auto m-3 btn btn-sm btn-outline-primary align-text-bottom"
										data-test-id="filing-payment-button"> Make Payment
						</button>
					}

				}
			</div>
		</div>
	</div>
}
