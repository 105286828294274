{
    "pipes": [
      {
        "uuid": "e4519095-1f63-4534-a5ac-2444eadecd49",
        "description": null,
        "emailAddress": "pipe303445070@mail.pipefy.com",
        "id": "303445070",
        "name": "Alabama - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "79a348db-76e2-4339-81b3-9d310013a7d3",
        "description": null,
        "emailAddress": "pipe303467539@mail.pipefy.com",
        "id": "303467539",
        "name": "Alaska - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "9dbb01fe-e9a6-4681-9c0c-02c068435bd0",
        "description": null,
        "emailAddress": "pipe303467564@mail.pipefy.com",
        "id": "303467564",
        "name": "Arizona - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "b93837ae-00fc-4dc0-bb5f-ca0e6e7f48d2",
        "description": null,
        "emailAddress": "pipe303467568@mail.pipefy.com",
        "id": "303467568",
        "name": "Arkansas - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "e9fc55dc-5b1e-485d-bafa-4802190536bd",
        "description": null,
        "emailAddress": "pipe303467573@mail.pipefy.com",
        "id": "303467573",
        "name": "California - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "0dbb423c-4a13-4088-83e0-5c9ee8ddb9a1",
        "description": null,
        "emailAddress": "pipe303476978@mail.pipefy.com",
        "id": "303476978",
        "name": "Colorado - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "ac9c3cdb-2d06-4133-852b-9c6c5ef031ee",
        "description": null,
        "emailAddress": "pipe303467588@mail.pipefy.com",
        "id": "303467588",
        "name": "Conneticuit - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "1df9a5fb-68ee-4e99-93d0-727ef1e385c7",
        "description": null,
        "emailAddress": "pipe303467592@mail.pipefy.com",
        "id": "303467592",
        "name": "Delaware - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "3e66fa03-a256-4786-aa42-9ad3ce3088da",
        "description": null,
        "emailAddress": "pipe303467593@mail.pipefy.com",
        "id": "303467593",
        "name": "District of Columbia - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "c74d96ac-7a88-40ab-97d2-24f113b55a1c",
        "description": null,
        "emailAddress": "pipe303467597@mail.pipefy.com",
        "id": "303467597",
        "name": "Florida - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "3cf48d32-98c8-44ed-9a44-7bd26b94e492",
        "description": null,
        "emailAddress": "pipe303467605@mail.pipefy.com",
        "id": "303467605",
        "name": "Georgia - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "69366fa4-f679-4435-b505-eddea491a6fd",
        "description": null,
        "emailAddress": "pipe303467618@mail.pipefy.com",
        "id": "303467618",
        "name": "Hawaii - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "14a53d47-4687-46b8-a233-5dfdb2ae2adf",
        "description": null,
        "emailAddress": "pipe303467649@mail.pipefy.com",
        "id": "303467649",
        "name": "Idaho - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "267f724b-ac64-4b4a-b96c-1f5e3d8d21d1",
        "description": null,
        "emailAddress": "pipe303467651@mail.pipefy.com",
        "id": "303467651",
        "name": "Illinios - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "5594789b-0b96-493d-9db5-a7b6007c2b9f",
        "description": null,
        "emailAddress": "pipe303467653@mail.pipefy.com",
        "id": "303467653",
        "name": "Indiana - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "20065bf2-6d76-4a21-a68e-c164cc0a005d",
        "description": null,
        "emailAddress": "pipe303467656@mail.pipefy.com",
        "id": "303467656",
        "name": "Iowa - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "beae7456-0c60-4db1-ab21-04e2832fb7cf",
        "description": null,
        "emailAddress": "pipe303467658@mail.pipefy.com",
        "id": "303467658",
        "name": "Kansas - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "8d513d81-9352-406e-9039-192f47533d42",
        "description": null,
        "emailAddress": "pipe303467664@mail.pipefy.com",
        "id": "303467664",
        "name": "Kentucky - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "e9d68c16-573c-4135-ab66-fa7a7713f7f0",
        "description": null,
        "emailAddress": "pipe303467666@mail.pipefy.com",
        "id": "303467666",
        "name": "Louisiana - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "55756e5a-e4c1-47c5-8a1a-d32f3d03d19a",
        "description": null,
        "emailAddress": "pipe303467671@mail.pipefy.com",
        "id": "303467671",
        "name": "Maine - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "85d92536-e8cc-4b6d-b466-cdb05b0bd57c",
        "description": null,
        "emailAddress": "pipe303467673@mail.pipefy.com",
        "id": "303467673",
        "name": "Maryland - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "40b89b4a-8c34-48c7-87a3-c2b889fa246f",
        "description": null,
        "emailAddress": "pipe303467675@mail.pipefy.com",
        "id": "303467675",
        "name": "Massachusetts - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "7ce5169f-f8fc-4fa5-afb2-6480ae5021f0",
        "description": null,
        "emailAddress": "pipe303467685@mail.pipefy.com",
        "id": "303467685",
        "name": "Michigian - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "f0117835-9314-4f35-b049-313bdd07227e",
        "description": null,
        "emailAddress": "pipe303467695@mail.pipefy.com",
        "id": "303467695",
        "name": "Minnesota - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "f59e9454-c040-421b-8318-7253279e4037",
        "description": null,
        "emailAddress": "pipe303467700@mail.pipefy.com",
        "id": "303467700",
        "name": "Mississippi - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "cd87174a-fd03-4bb2-bc17-6b5f28b1c7fd",
        "description": null,
        "emailAddress": "pipe303467711@mail.pipefy.com",
        "id": "303467711",
        "name": "Missouri - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "0cc0547b-06b5-44ba-9417-03ac731684f8",
        "description": null,
        "emailAddress": "pipe303477905@mail.pipefy.com",
        "id": "303477905",
        "name": "Montana - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "0f8e13b4-1675-494f-aab3-8b69280ce55d",
        "description": null,
        "emailAddress": "pipe303467718@mail.pipefy.com",
        "id": "303467718",
        "name": "Nebraska - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "e7799187-75da-45c1-bf5c-2d50b01b5722",
        "description": null,
        "emailAddress": "pipe303467723@mail.pipefy.com",
        "id": "303467723",
        "name": "Nevada - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "34c96261-a2df-4e6b-82e8-a747079a72b3",
        "description": null,
        "emailAddress": "pipe303467737@mail.pipefy.com",
        "id": "303467737",
        "name": "New Hampshire - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "c401b6a2-8f85-4942-9c0b-f9de6d38fdd6",
        "description": null,
        "emailAddress": "pipe303467740@mail.pipefy.com",
        "id": "303467740",
        "name": "New Jersey - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "ad331797-7965-4a5e-aca0-c6e3d8aae944",
        "description": null,
        "emailAddress": "pipe303468098@mail.pipefy.com",
        "id": "303468098",
        "name": "New Mexico - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "a26db5f6-658d-4650-9a55-deef3516f4b6",
        "description": null,
        "emailAddress": "pipe303468102@mail.pipefy.com",
        "id": "303468102",
        "name": "New York - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "c86e2368-2683-4036-8811-d9033a5027d7",
        "description": null,
        "emailAddress": "pipe303468110@mail.pipefy.com",
        "id": "303468110",
        "name": "North Carolina - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "d018c06b-d945-4a60-ac60-65066e7e70f4",
            "description": "",
            "id": "business_profile_id",
            "required": false,
            "label": "Business Profile ID",
            "type": "short_text",
            "index": -1
          },
          {
            "uuid": "1bedd86b-4374-43fc-b31b-da6940673a4c",
            "description": "",
            "id": "legal_business_name",
            "required": false,
            "label": "Legal Business Name",
            "type": "short_text",
            "index": -0.75
          },
          {
            "uuid": "cdf947bc-0b00-41a4-9570-05d500c50897",
            "description": "",
            "id": "business_address_line_1",
            "required": false,
            "label": "Business Address line 1",
            "type": "email",
            "index": -0.5
          },
          {
            "uuid": "189267b5-4c1c-48b5-9ed7-81330d37ecb6",
            "description": "",
            "id": "business_address_line_2",
            "required": false,
            "label": "Business Address line 2",
            "type": "email",
            "index": -0.25
          },
          {
            "uuid": "103a0274-465f-43de-ac05-a99eedca6a0d",
            "description": "",
            "id": "business_city",
            "required": true,
            "label": "Business City",
            "type": "short_text",
            "index": 0
          },
          {
            "uuid": "86844f2e-7c21-4f28-85c4-292911b98165",
            "description": "",
            "id": "business_state",
            "required": true,
            "label": "Business State",
            "type": "short_text",
            "index": 0.5
          },
          {
            "uuid": "bd0ba099-6570-4d97-8894-efb85ae43b52",
            "description": "",
            "id": "business_zipcode",
            "required": true,
            "label": "Business zipcode",
            "type": "short_text",
            "index": 0.75
          },
          {
            "uuid": "89d67ddc-bf66-4786-a03a-4c62cd73e960",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "a4cff0f9-0dbf-4fe0-91f7-cb7b67fa3d8c",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          },
          {
            "uuid": "cf1846f6-58f3-4cb7-990a-d0f1f0570de7",
            "description": "",
            "id": "owners_full_name",
            "required": true,
            "label": "Owners Full Name",
            "type": "short_text",
            "index": 0.96875
          },
          {
            "uuid": "85dcb4d1-ce5a-47f1-8269-78e357f02c98",
            "description": "",
            "id": "owners_address_line_1",
            "required": true,
            "label": "Owners Address line 1",
            "type": "short_text",
            "index": 0.984375
          },
          {
            "uuid": "79774825-9f61-4414-b47e-c192bb70ecd1",
            "description": "",
            "id": "owners_address_line_2",
            "required": true,
            "label": "Owners Address line 2",
            "type": "short_text",
            "index": 0.9921875
          },
          {
            "uuid": "9d9d5a02-7189-4e60-9004-335e9f4c42f8",
            "description": "",
            "id": "owners_city",
            "required": true,
            "label": "Owners City",
            "type": "short_text",
            "index": 0.99609375
          },
          {
            "uuid": "15506044-59de-43b9-b547-4ed164e34fee",
            "description": "",
            "id": "owners_state",
            "required": true,
            "label": "Owners State",
            "type": "short_text",
            "index": 0.998046875
          },
          {
            "uuid": "d705dff6-5e13-4222-b21e-c9cc13d87c35",
            "description": "",
            "id": "owners_zipcode",
            "required": true,
            "label": "Owners Zipcode",
            "type": "short_text",
            "index": 0.9990234375
          }
        ]
      },
      {
        "uuid": "1d159259-028f-487b-8ea4-29173592f394",
        "description": null,
        "emailAddress": "pipe303468121@mail.pipefy.com",
        "id": "303468121",
        "name": "North Dakota - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "15a3c705-636d-42cd-b0f4-fb28eb3e786d",
        "description": null,
        "emailAddress": "pipe303468130@mail.pipefy.com",
        "id": "303468130",
        "name": "Ohio - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "99cc39d1-fcf6-4277-81f1-35a7505fe906",
        "description": null,
        "emailAddress": "pipe303468132@mail.pipefy.com",
        "id": "303468132",
        "name": "Oklahoma - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "876aeef0-ac68-4623-8623-2188c96856a4",
        "description": null,
        "emailAddress": "pipe303468143@mail.pipefy.com",
        "id": "303468143",
        "name": "Oregon - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "857f8379-16a8-4dd7-9c8e-db48b44f39ed",
        "description": null,
        "emailAddress": "pipe303468153@mail.pipefy.com",
        "id": "303468153",
        "name": "Pennsylvania - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "f77144e1-8880-4aa2-aae0-719a3d2d3e60",
        "description": null,
        "emailAddress": "pipe303468160@mail.pipefy.com",
        "id": "303468160",
        "name": "Rhode Island - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "5cf3fb35-4a29-4f19-9159-2a3da8877384",
        "description": null,
        "emailAddress": "pipe303468169@mail.pipefy.com",
        "id": "303468169",
        "name": "South Carolina - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "4f54d423-f606-4a29-b4c7-1c346ee349c6",
        "description": null,
        "emailAddress": "pipe303468178@mail.pipefy.com",
        "id": "303468178",
        "name": "South Dakota - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "537e5675-a6f5-4f45-b9f0-7f3f7e690179",
        "description": null,
        "emailAddress": "pipe303468180@mail.pipefy.com",
        "id": "303468180",
        "name": "Tennesse - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "c30c7cc0-5327-4587-b025-e6399fb9046f",
        "description": null,
        "emailAddress": "pipe303468183@mail.pipefy.com",
        "id": "303468183",
        "name": "Texas - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "9e42f5ca-6a20-4c84-b64b-12d7c5acd718",
        "description": null,
        "emailAddress": "pipe303468184@mail.pipefy.com",
        "id": "303468184",
        "name": "Utah - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "79c574d2-cef8-40cd-871e-db1023041605",
        "description": null,
        "emailAddress": "pipe303468190@mail.pipefy.com",
        "id": "303468190",
        "name": "Vermont - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "c5e309e2-3863-43f2-b58c-ba7b40cee8dc",
        "description": null,
        "emailAddress": "pipe303468192@mail.pipefy.com",
        "id": "303468192",
        "name": "Virginia - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "9c930d43-9ff6-40b6-8abb-d21f827b1222",
        "description": null,
        "emailAddress": "pipe303468195@mail.pipefy.com",
        "id": "303468195",
        "name": "Washington - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "a7a9c854-b777-4e50-a247-57732791d667",
            "description": "",
            "id": "business_profile_id",
            "required": false,
            "label": "Business Profile ID",
            "type": "short_text",
            "index": -1
          },
          {
            "uuid": "842f5933-cc2d-4615-a65e-b6ba1493f572",
            "description": "",
            "id": "legal_business_name",
            "required": false,
            "label": "Legal Business Name",
            "type": "short_text",
            "index": -0.75
          },
          {
            "uuid": "a62564a4-4ec9-4cba-ae6f-19901c724c1d",
            "description": "",
            "id": "business_address_line_1",
            "required": false,
            "label": "Business Address line 1",
            "type": "email",
            "index": -0.5
          },
          {
            "uuid": "5703a726-fdae-4c8e-844d-bdfbe13d6be0",
            "description": "",
            "id": "business_address_line_2",
            "required": false,
            "label": "Business Address line 2",
            "type": "email",
            "index": -0.25
          },
          {
            "uuid": "2ddb5201-26b2-4bb8-bfe7-996ba8fb4b28",
            "description": "",
            "id": "business_city",
            "required": true,
            "label": "Business City",
            "type": "short_text",
            "index": 0
          },
          {
            "uuid": "7cbd43be-818c-422b-8a66-a54b7642b875",
            "description": "",
            "id": "business_state",
            "required": true,
            "label": "Business State",
            "type": "short_text",
            "index": 0.5
          },
          {
            "uuid": "f064998b-f3f5-4d9c-bd25-778e55252a85",
            "description": "",
            "id": "business_zipcode",
            "required": true,
            "label": "Business zipcode",
            "type": "short_text",
            "index": 0.75
          },
          {
            "uuid": "526c1c87-989c-44d0-8ec0-fdb4220b4060",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "6f847a76-fd33-4d56-8fdc-fba48134d21f",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          },
          {
            "uuid": "ec811265-d545-434f-b865-1d55b743d1d0",
            "description": "",
            "id": "owners_full_name",
            "required": true,
            "label": "Owners Full Name",
            "type": "short_text",
            "index": 0.96875
          },
          {
            "uuid": "6452771c-7180-4a46-bb76-5d25e3f3a7b9",
            "description": "",
            "id": "owners_address_line_1",
            "required": true,
            "label": "Owners Address line 1",
            "type": "short_text",
            "index": 0.984375
          },
          {
            "uuid": "71aa39bc-40a1-488e-97d7-79da42fc31a6",
            "description": "",
            "id": "owners_address_line_2",
            "required": true,
            "label": "Owners Address line 2",
            "type": "short_text",
            "index": 0.9921875
          },
          {
            "uuid": "860425da-3be8-497c-907f-9d1b3a05b9ea",
            "description": "",
            "id": "owners_city",
            "required": true,
            "label": "Owners City",
            "type": "short_text",
            "index": 0.99609375
          },
          {
            "uuid": "12c35a06-321a-4232-84a9-e60fbd65d78c",
            "description": "",
            "id": "owners_state",
            "required": true,
            "label": "Owners State",
            "type": "short_text",
            "index": 0.998046875
          },
          {
            "uuid": "d2b7237b-c55c-438d-a856-6d2b6154a071",
            "description": "",
            "id": "owners_zipcode",
            "required": true,
            "label": "Owners Zipcode",
            "type": "short_text",
            "index": 0.9990234375
          }
        ]
      },
      {
        "uuid": "9c1ba039-67d6-4bf5-a227-16839b8a4abe",
        "description": null,
        "emailAddress": "pipe303468204@mail.pipefy.com",
        "id": "303468204",
        "name": "West Virginia - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "7b587fa7-f3e1-4e87-b473-12d7f8e70f8a",
        "description": null,
        "emailAddress": "pipe303478680@mail.pipefy.com",
        "id": "303478680",
        "name": "Wisconsin - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      },
      {
        "uuid": "4c03679c-6d36-4081-80a1-0af9450769f1",
        "description": null,
        "emailAddress": "pipe303478690@mail.pipefy.com",
        "id": "303478690",
        "name": "Wyoming - State Tax Registration",
        "organizationId": "301158188",
        "start_form_fields": [
          {
            "uuid": "264287b3-407e-4a4b-957b-bd1f838e6196",
            "description": "",
            "id": "business_ein_number",
            "required": true,
            "label": "Business EIN Number",
            "type": "short_text",
            "index": 0.875
          },
          {
            "uuid": "cd1a83f9-9e74-442c-a4d2-724a9335a1d9",
            "description": "",
            "id": "business_type_e_g_llc_or_s_corp",
            "required": true,
            "label": "Business Type (e.g. LLC or S Corp)",
            "type": "short_text",
            "index": 0.9375
          }
        ]
      }
    ]
  }