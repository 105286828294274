import { featureFlag } from '@atomickit/atomickit-constants';

export const environment = {
	production: true,
  NAME: 'dev',
	API_URL: 'https://dev-server.atomictax.com/api/v1',
	REGION: 'us-east-1',
	USER_POOL_ID: 'us-east-1_4xyqQ0oo8',
	USER_POOL_WEB_CLIENT_ID: '2v244a1t40uccvjuma5u3vic9a',
	AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
  SENTRY_DSN: 'https://d8ac4d89955f4215be8b7408af90ae1a@o1319042.ingest.sentry.io/6574097',
  UPLOAD_FILES: {
    BUCKET: 'dev-files.atomickit.io',
    REGION: 'us-east-1',
  },
  COGNITO_IDENTITY_POOL_ID: 'us-east-1:e0447cbe-dd13-4fb4-ab80-2dbc25c4b064',
  STRIPE_PUB_KEY: 'pk_test_1ZM0ULAwYmqSeboV5q8ITy6G7a21WEdRq9ky3LdqoaHoZqW0tcAtnliYDRFbAnQecNfSWP2YpRrhbdT7mCZ6KLMdb00pQoScdBW',
  WEBSOCKET_URL: 'wss://dev-server.atomictax.com/ws/v1',
  FEATURE_FLAG: {
    multi_account_switch: featureFlag.FEATURE_FLAG.DEV.multi_account_switch,
    teammates_signup: featureFlag.FEATURE_FLAG.PROD.teammates_signup,
    amazon_linked_account: featureFlag.FEATURE_FLAG.DEV.amazon_linked_account,
    shopify_credit: true,
    suspend_autofiling: featureFlag.FEATURE_FLAG.DEV.suspend_autofiling,
    linked_account_processing: featureFlag.FEATURE_FLAG.DEV.linked_account_processing,
    autofiling_v2: featureFlag.FEATURE_FLAG.DEV.autofiling_v2,
  },
  DRIP_ACCOUNT_ID: '7936728',
  MIX_PANEL_TOKEN: 'd1fd4001a7c32c53f6ebe870d5f82487',
};
