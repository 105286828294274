import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { AuthService } from '@app/auth/auth.service';
import { Notifications } from '@shared/components/navbar/nav-bar.component';


@Injectable({
  providedIn: 'root',
})
export class WebsocketConfigService {
  webSocket: WebSocket;
  notificationsSubject: BehaviorSubject<Array<Notifications>> = new BehaviorSubject([]);
  all_notifications = [];
  private readonly token: string;

  constructor(
    private readonly authService: AuthService,
    private readonly toastr: ToastrService,
  ) {
    this.connect();
    this.webSocketMessageHandler();
  }

  getToken() {
    const authInfo = this.authService.getTokenAndValidity();
    if (!authInfo) return '';
    return authInfo?.token;
  }

  connect() {
    // see https://stackoverflow.com/questions/4361173/http-headers-in-websockets-client-api
    this.webSocket = new WebSocket(`${AppConfig.WEBSOCKET_URL}?authorization=${this.getToken()}`);
    this.webSocket.onclose = (e) => {
      // Attempt reconnecting after one second
      setTimeout(() => {
        this.connect();
        this.webSocketMessageHandler();
      }, 1000);
    };
  }

  webSocketMessageHandler() {
    this.webSocket.onmessage = (messageEvent: MessageEvent) => {
      const message = JSON.parse(messageEvent.data);
      if (message.error) {
        this.toastr.error(message.message);
        return;
      }

      switch (message.path) {
        case '/notifications/list':
          this.all_notifications = message.response;
          this.notificationsSubject.next(message.response);
          break;
        case '/notifications/read':
          const read_notifications = message.response;
          const unread_notifications = this.all_notifications.filter(
            (notification) =>
              !read_notifications
                .map((n) => n.notification_id)
                .includes(notification.notification_id)
          );
          this.all_notifications = [
            ...unread_notifications,
            ...read_notifications,
          ];
          this.notificationsSubject.next(this.all_notifications);
          break;
        default:
          this.listNotifications();
      }
    };
  }

  listNotifications() {
    const listNotifications = {
      httpMethod: 'GET',
      path: '/notifications/list',
      headers: {
        authorization: this.getToken(),
      },
    };

    this.webSocket.send(JSON.stringify(listNotifications));
  }

  readNotifications(notification_ids: string[]) {
    const readNotifications = {
      httpMethod: 'POST',
      path: '/notifications/read',
      headers: {
        authorization: this.getToken(),
      },
      body: {
        notification_ids,
      },
    };

    this.webSocket.send(JSON.stringify(readNotifications));
  }

  deleteNotification(notification_id: string) {
    const deleteNotification = {
      httpMethod: 'DELETE',
      path: '/notifications/delete',
      headers: {
        authorization: this.getToken(),
      },
      body: {
        notification_id: notification_id,
      },
    };

    this.webSocket.send(JSON.stringify(deleteNotification));
  }
}
