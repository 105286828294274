const ApiRequest = require('./app-constants/api_request.js');
const Countries = require('./app-constants/countries.js');
const Events = require('./app-constants/events.js');
const General = require('./app-constants/general.js');
const Periods = require('./app-constants/periods.js');
const Products = require('./app-constants/products.js');
const RemoteSellersConfig = require('./app-constants/remote_sellers_data.js');
const Reports = require('./app-constants/reports.js');
const States = require('./app-constants/states.js');
const UserData = require('./app-constants/user_data.js');
const featureFlag = require('./app-constants/featureflag');
const autoRegisterConfig = require('./autoregister/states_config.json');
const autoFilingConfig = require('./autofiling/state_config.json');
const autoRegisterPipeIds = require('./autoregister/pipeIds');
const autoFilingPipeIds = require('./autofiling/pipeIds');
module.exports = {
  ApiRequest,
  Countries,
  Events,
  General,
  Periods,
  Products,
  RemoteSellersConfig,
  Reports,
  States,
  UserData,
  featureFlag,
  // auto-register and auto-filing json files
  autoRegisterConfig,
  autoFilingConfig,
  // pipeIds
  autoRegisterPipeIds,
  autoFilingPipeIds
};