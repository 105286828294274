export class StateNexusSetting {
    business_name: string = '';
    calculate_sales_tax: boolean = false;
    created_at?: Date;
    nexus_establishment_date: string = '';
    payment_schedule: string = '';
    shipping_tax_included: string = '';
    state_nexus_id?: number;
    state: string = '';
    tax_id: string = '';
    updated_at?: Date;
    user_id?: string = '';
}
