const {
  Products
} = require('../app-constants/products');
const {
  US_STATES
} = require('../app-constants/states');
const autoRegisterPipeIds = {
  [Products.Register]: {
    // point to SupportedStates[] values
    [US_STATES.Alabama]: '303445070',
    [US_STATES.Alaska]: '303467539',
    [US_STATES.Arizona]: '303467564',
    [US_STATES.Arkansas]: '303467568',
    [US_STATES.California]: '303467573',
    [US_STATES.Colorado]: '303476978',
    [US_STATES.Connecticut]: '303467588',
    [US_STATES.Delaware]: '303467592',
    [US_STATES['District of Columbia']]: '303467593',
    [US_STATES.Florida]: '303467597',
    [US_STATES.Georgia]: '303467605',
    [US_STATES.Hawaii]: '303467618',
    [US_STATES.Idaho]: '303467649',
    [US_STATES.Illinois]: '303467651',
    [US_STATES.Indiana]: '303467653',
    [US_STATES.Iowa]: '303467656',
    [US_STATES.Kansas]: '303467658',
    [US_STATES.Kentucky]: '303467664',
    [US_STATES.Louisiana]: '303467666',
    [US_STATES.Maine]: '303467671',
    [US_STATES.Maryland]: '303467673',
    [US_STATES.Massachusetts]: '303467675',
    [US_STATES.Michigan]: '303467685',
    [US_STATES.Minnesota]: '303467695',
    [US_STATES.Mississippi]: '303467700',
    [US_STATES.Missouri]: '303467711',
    [US_STATES.Montana]: '303477905',
    [US_STATES.Nebraska]: '303467718',
    [US_STATES.Nevada]: '303467723',
    [US_STATES['New Hampshire']]: '303467737',
    [US_STATES['New Jersey']]: '303467740',
    [US_STATES['New Mexico']]: '303468098',
    [US_STATES['New York']]: '303468102',
    [US_STATES['North Carolina']]: '303468110',
    [US_STATES['North Dakota']]: '303468121',
    [US_STATES.Ohio]: '303468130',
    [US_STATES.Oklahoma]: '303468132',
    [US_STATES.Oregon]: '303468143',
    [US_STATES.Pennsylvania]: '303468153',
    // No Puerto Rico in document
    [US_STATES['Puerto Rico']]: null,
    [US_STATES['Rhode Island']]: '303468160',
    [US_STATES['South Carolina']]: '303468169',
    [US_STATES['South Dakota']]: '303468178',
    [US_STATES.Tennessee]: '303468180',
    [US_STATES.Texas]: '303468183',
    [US_STATES.Utah]: '303468184',
    [US_STATES.Vermont]: '303468190',
    [US_STATES.Virginia]: '303468192',
    [US_STATES.Washington]: '303468195',
    [US_STATES['West Virginia']]: '303468204',
    [US_STATES.Wisconsin]: '303478680',
    [US_STATES.Wyoming]: '303478690'
  }
};
module.exports = autoRegisterPipeIds;