import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';

import { PaymentInformation, PaymentMethod, PaymentMethodWithToken } from './payment.interface';
import { PlanAndBillingService } from '@services/plan-and-billing/plan-and-billing.service';
import { BusinessProfile } from '@shared/meta-data/business-profile.meta';
import { StorageService } from '@shared/services/storage/storage.service';
import { StripeCardToken } from '@shared/meta-data/stripe-card-token.meta';
import { environment } from '../../../../environments/environment';
import { BillingProviderEnum } from '@shared/meta-data';
import { STORE_KEYS } from '@app/utils/constants';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {
  private readonly planService: PlanAndBillingService = inject(PlanAndBillingService);
  private readonly store: StorageService = inject(StorageService);

  @Input() defaultPaymentMethod: PaymentMethodWithToken;
  @Input() isMakingPayment: boolean = false;
  @Input({
    transform: (details?: Partial<PaymentInformation>) => new PaymentInformation(details)
  }) paymentDetails: PaymentInformation = new PaymentInformation();
  @Output() selection: EventEmitter<PaymentMethodWithToken> = new EventEmitter();
  @Output() clearSelection: EventEmitter<null> = new EventEmitter();

  isShopifyBusiness: boolean;
  shopifyCreditBalance: number;

  paymentMethods: PaymentMethodWithToken[] = [];

  /* Card Payment Method properties */
  displayAddNewCardForm = false;
  tokenizingCard = false;
  cardDisplayStatus: 'isLoadingCards' | 'hasCards' | 'hasNoCard' = 'isLoadingCards';
  selectedCard: PaymentMethodWithToken;
  selectedShopifyBilling: 'credit' | 'external';

  get freezeAllActions(): boolean {
    return this.tokenizingCard || this.isMakingPayment;
  }

  ngOnInit(): void {
    this.determineAccount();
  }

  determineAccount() {
    this.isShopifyBusiness = this.store.get<BusinessProfile>(STORE_KEYS.BUSINESS_PROFILE_KEY).shopify_billing;

    if (this.isShopifyBusiness) {
      this.fetchCreditBalance();
    } else {
      this.fetchUserPaymentMethod();
    }
  }

  emitSelection() {
    this.selection.emit({
      ...this.selectedCard,
      provider: this.isShopifyBusiness ? BillingProviderEnum.SHOPIFY : BillingProviderEnum.STRIPE,
      use_credits: this.selectedShopifyBilling === 'credit'
    });
  }

  fetchCreditBalance(): void {
    if (environment.FEATURE_FLAG.shopify_credit) {
      this.planService.fetchAtomicTaxCredits()
        .subscribe({
          next: ({ available_credits }) => this.shopifyCreditBalance = available_credits
        });
    }
  }

  fetchUserPaymentMethod() {
    this.planService.fetchPaymentMethod().subscribe(res => {
      this.paymentMethods = res;

      /**
       * If there is a default payment method provided,
       * ensure that it is the default selected whether it exist in the available methods or not
       */
      if (this.defaultPaymentMethod) {
        const paymentMethod = this.paymentMethods.find(paymentMethod => paymentMethod.id === this.defaultPaymentMethod.id);
        if (!paymentMethod) {
          this.paymentMethods.push(this.defaultPaymentMethod);
        }
        this.selectCard(paymentMethod || this.defaultPaymentMethod);
      }

      this.setCardDisplayStatus();
    });
  }

  handleCardTokenizeStartEvent() {
    this.tokenizingCard = true;
  }

  handleCardTokenizeErrorEvent() {
    this.tokenizingCard = false;
  }

  handleCardTokenizedEvent(card: StripeCardToken) {
    // Mind the order so that we can be able to unfreeze actions and select this card as default after adding it
    this.tokenizingCard = false;

    // Construct a new payment method object from the card token
    const newPaymentMethod: PaymentMethodWithToken = {
      card: {
        brand: card.card.brand,
        last4: card.card.last4,
        exp_month: card.card.exp_month,
        exp_year: card.card.exp_year
      } as PaymentMethod['card'],
      token: card.id,
      id: undefined,
      metadata: '',
      type: 'card'
    };

    // Add the new constructed object to the list of payment methods displayed
    this.paymentMethods.push(newPaymentMethod);

    // Select the newly added payment method as default
    this.selectCard(newPaymentMethod);
    // toggle the card display
    this.setCardDisplayStatus();

  }

  selectCard(card: PaymentMethodWithToken) {
    if (this.freezeAllActions) {
      return;
    }

    // Do not select a card if there is a current process going on to add a new card or tokenize a card
    this.selectedCard = card;
    this.emitSelection();
  }

  toggleAddNewCardDisplay() {
    if (this.freezeAllActions) {
      return;
    }
    this.selectedCard = null;
    this.displayAddNewCardForm = true;
    this.clearSelection.emit();
  }

  private setCardDisplayStatus() {
    this.cardDisplayStatus = this.paymentMethods.length > 0 ? 'hasCards' : 'hasNoCard';
  }

  selectShopifyBilling(shopifyBilling: 'credit' | 'external'): void {
    this.selectedShopifyBilling = shopifyBilling;
  }
}
