import { environment } from '../environments/environment';

export class AppConfig {
  public static readonly API_URL = environment.API_URL;
  public static readonly REGION = environment.REGION;
  public static readonly COGNITO_IDENTITY_POOL_ID = environment.COGNITO_IDENTITY_POOL_ID;
  public static readonly USER_POOL_ID = environment.USER_POOL_ID;
  public static readonly USER_POOL_WEB_CLIENT_ID = environment.USER_POOL_WEB_CLIENT_ID;
  public static readonly AUTHENTICATION_FLOW_TYPE = environment.AUTHENTICATION_FLOW_TYPE;
  public static readonly UPLOAD_FILES_BUCKET = environment.UPLOAD_FILES.BUCKET;
  public static readonly UPLOAD_FILES_REGION = environment.UPLOAD_FILES.REGION;
  public static readonly WEBSOCKET_URL = environment.WEBSOCKET_URL;
}
