import { RouteInfo } from '@shared/components/sidebar/sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Menu',
    icon: 'dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/cooperate-tax/home',
    title: 'Home',
    icon: 'home',
    class: '',
    label: '',
    labelClass: 'side-badge badge badge-info',
    extralink: false,
    submenu: []
  },
  {
    path: '/cooperate-tax/filing--history',
    title: 'Filing History',
    icon: 'archive',
    class: '',
    label: '',
    labelClass: 'side-badge badge badge-info',
    extralink: false,
    submenu: []
  }
];
