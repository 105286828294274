import {
  SignUpOutput,
  type AuthSession,
  type SignInOutput
} from 'aws-amplify/auth';
import jwt_decode from 'jwt-decode';
import moment, { Moment } from 'moment';
import * as Sentry from '@sentry/angular-ivy';
import { inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { General } from '@atomickit/atomickit-constants';
import { NavigationExtras, Router } from '@angular/router';
import { defer, from, Observable, throwError, zip } from 'rxjs';
import { catchError, switchMap, takeWhile, tap } from 'rxjs/operators';

import { AtomickitUser, CognitoUserInterface, CurrentUserInfo, User, UserInterface } from '@shared/meta-data';
import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { AnalyticsManagerService } from '@app/utils/analytics-manager/analytics-manager.service';
import { BusinessProfileService } from '@services/business-profile/business-profile.service';
import { SalesTaxConfigService } from '@services/sales-tax-config/sales-tax-config.service';
import { ReferenceDataService } from '@services/reference-data/reference-data.service';
import { StorageService } from '@shared/services/storage/storage.service';
import { EventTypes } from '@shared/meta-data/analytics-manager.meta';
import { AppBanner } from '@shared/meta-data/app-banner.meta';
import { STORE_KEYS } from '@app/utils/constants';
import AmplifyAuth from '@app/auth/amplify-auth';
import { Store } from '@ngrx/store';
import * as formApp from '@app/@store/app/app.reducer';
import * as AppActions from '@app/@store/app/app.actions';
import { autoSignIn } from '@aws-amplify/auth';
@Injectable()
export class AuthService {
  redirectUrl: string;
  passwordResetEmail: string;

  private readonly handleError: HandleError;

  private modalService: NgbModal = inject(NgbModal);
  private store: Store = inject(Store<formApp.AppState>);

  constructor(
    private readonly router: Router,
    private businessProfileService: BusinessProfileService,
    private referenceDataService: ReferenceDataService,
    private storage: StorageService,
    private analyticsManagerService: AnalyticsManagerService,
    private readonly salesTaxConfigService: SalesTaxConfigService,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('Auth Service')
  }

  get user(): User {
    return this.isAuthenticated() && new User(this.getUserData());
  }

  get trialExpiryDate(): Moment {
    return moment(this.businessProfileService.businessProfile?.created_at).add(General.SUBSCRIPTION_TRIAL_PERIOD, 'days');
  }

  get trialHasExpired(): boolean {
    return this.businessProfileService.businessProfile?.account_status === General.ACCOUNT_STATUS.TRIAL_EXPIRED;
  }

  get isInTrial(): boolean {
    return this.businessProfileService.businessProfile?.account_status === General.ACCOUNT_STATUS.IN_TRIAL;
  }

  get subscriptionCancelled(): boolean {
    return this.businessProfileService.businessProfile?.account_status === General.ACCOUNT_STATUS.CANCELLED;
  }

  get subscriptionPendingCancellation(): boolean {
    return this.businessProfileService.businessProfile?.account_status === General.ACCOUNT_STATUS.PENDING_CANCELLATION;
  }


  get appBanner(): AppBanner | undefined {
    switch (true) {
      case this.isInTrial:
        return {
          message: `Your trial expires ${this.trialExpiryDate.startOf('day').fromNow()}`,
          link: ['account-settings', 'plan'],
          hint: 'Click here to activate'
        };
    
      case this.subscriptionPendingCancellation:
        return {
          message: `Your subscription is at risk of cancellation, click here to fix`,
          link: ['account-settings', 'plan'],
          hint: ''
        };
    
      case this.subscriptionCancelled:
        return {
          message: `Your subscription has been cancelled, click here to subscribe`,
          link: ['account-settings', 'plan'],
          hint: '',
        };
    
      case this.trialHasExpired:
        return {
          message: `Your trial has expired, click here to subscribe`,
          link: ['account-settings', 'plan'],
          hint: '',
        };
    
      default:
        return undefined;
    }
  }

  get userInfo(): CurrentUserInfo {
    return {
      firstName: this.user?.given_name,
      lastName: this.user?.family_name,
      email: this.user?.email,
      businessName: this.businessProfileService.businessProfile?.business_name,
      hasMultipleBusinesses: this.hasMultipleBusinesses
    };
  }

  get hasCompletedOnboarding(): boolean {
    return !!this.businessProfileService.businessProfile?.business_profile_id;
  }

  get hasMultipleBusinesses(): boolean {
    return this.businessProfileService.businessProfiles?.length > 1;
  }

  get hasAccessibleBusinesses(): boolean {
    return this.businessProfileService.businessProfiles?.some(b => b.revoked_at === null);
  }

  static getTokenExpirationTimeValueInMilliseconds(token: string): number {
    try {
      const decoded = jwt_decode<any>(token);

      if (!decoded.exp) {
        return 0;
      }

      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);

      return date.valueOf();
    } catch (e) {
      return 0;
    }
  }

  private static checkIfTokenIsExpiredOrValid(token: string): boolean {
    return AuthService.getTokenExpirationTimeValueInMilliseconds(token) > new Date().valueOf();
  }

  private static getTimeToTokenExpiry(token: string): number {
    return AuthService.getTokenExpirationTimeValueInMilliseconds(token) - new Date().valueOf();
  }

  handleAuthSuccess(credentials: Partial<UserInterface>): Observable<any> {
    StorageService.clearStoreKeys();

    this.storage.save(STORE_KEYS.USER_AUTH_KEY, credentials);
    this.logoutWhenTokenExpires();
    return this.loadAndCacheUserData();
  }

  getUserData(): UserInterface | undefined {
    return this.storage.get<UserInterface>(STORE_KEYS.USER_AUTH_KEY);
  }

  isAuthenticated(): boolean {
    return AuthService.checkIfTokenIsExpiredOrValid(this.getUserData()?.token);
  }

  getTokenAndValidity(): { token: string, isValid: boolean } {
    return {
      token: this.getUserData()?.token,
      isValid: this.isAuthenticated()
    };
  }

  logoutUser(redirectUrl?: string, authenticateThrough: 'login' | 'register' = 'login'): void {
    defer(() => from(AmplifyAuth.signOut())).subscribe();

    StorageService.clearStoreKeys();
    this.store.dispatch(AppActions.ResetAllStores()); 
    this.modalService.dismissAll();

    let navigationOptions: NavigationExtras = {};
    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
      navigationOptions.queryParamsHandling = 'merge'
    }

    this.router.navigate([`/${authenticateThrough}`], navigationOptions);
  }

  logoutWhenTokenExpires() {
    setTimeout(
      () => this.logoutUser(),
      AuthService.getTimeToTokenExpiry(this.getUserData().token)
    );
  }

  /** AWS Amplify Authentication **/
  verifyEmailAddressToken(email: string, code: string): Observable<SignUpOutput> {
    return defer(() => from(
      AmplifyAuth.confirmSignUp({ username: email, confirmationCode: code })
    ))
      .pipe(
        catchError(this.redirectUnconfirmedUser({email})),
        catchError(this.conceiveDisabledUser()),
        switchMap(() => from(autoSignIn())),
        switchMap(() => this.startNewSession(true)),
        tap({
          next: (user) => this.router.navigateByUrl(this.redirectUrl || '/dashboard'),
          error: () => this.logoutUser()
        }),
        catchError(this.handleError('Verify Email Address', null))
      );
  }

  resendVerificationCode(email: string): Observable<any> {
    return defer(() => from(AmplifyAuth.resendSignUpCode({ username: email })))
      .pipe(
        catchError(this.handleError('Resend Verification Code', null))
      );
  }

  signUpUser(user: Partial<AtomickitUser>): Observable<any> {
    return defer(() => from(AmplifyAuth.signUp({
      username: user.email,
      password: user.password,
      options: {
        userAttributes: {
          email: user.email,
          given_name: user.first_name,
          family_name: user.last_name,
          phone_number: user.phone_number,
          'custom:UserType': user.user_type,
          'custom:shopifyBilling': user.shopify_billing ? '1' : '0',
          'custom:UserTrialExpDate': `${moment().add(General.SUBSCRIPTION_TRIAL_PERIOD, 'days').valueOf()}`,
          'custom:currentBizProfileId': '0'
        },
        autoSignIn: true,
      }
    }))
      .pipe(
        catchError(this.handleError('Sign Up User')),
        tap({
          next: () => this.analyticsManagerService.sendEvent(EventTypes.SIGNUP_COMPLETED, {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email
          })
        })
      ));
  }

  signInUser(user: Partial<AtomickitUser>): Observable<SignInOutput> {
    return defer(() => from(
      AmplifyAuth.signIn({
        username: user.email.toString(),
        password: user.password.toString()
      })
    ))
      .pipe(
        catchError(this.redirectUnconfirmedUser(user)),
        catchError(this.conceiveDisabledUser()),
        takeWhile(this.checkForNextSteps(user.email)),
        switchMap(() => this.startNewSession(true)),
        catchError(this.handleError('Sign In')),
        tap({
          next: () => this.router.navigateByUrl(this.redirectUrl || '/dashboard'),
          error: () => this.logoutUser()
        })
      );
  }

  setNewPassword(user: Partial<AtomickitUser>): Observable<any> {
    return from(AmplifyAuth.confirmSignIn({
      challengeResponse: user.password,
      options: {
        userAttributes: {
          given_name: user.first_name,
          family_name: user.last_name,
          phone_number: user.phone_number
        }
      }
    }))
      .pipe(
        catchError(this.conceiveDisabledUser()),
        switchMap(() => this.startNewSession()),
        tap({
          next: () => {
            this.router.navigateByUrl('/dashboard');
          }
        }),
        catchError(this.handleError('Setup profile'))
      );
  }

  sendPasswordResetCode(email: string): Observable<any> {
    return from(AmplifyAuth.resetPassword({ username: email }))
      .pipe(
        catchError(this.handleError('Request Password Reset Code', null))
      );
  }

  resetPassword({ email, code, password }): Observable<string> {
    return from(AmplifyAuth.confirmResetPassword({ username: email, confirmationCode: code, newPassword: password }))
      .pipe(
        catchError(this.handleError('Reset Password', null)),
        tap( () => this.router.navigateByUrl('/login')),
        tap( () => delete this.passwordResetEmail)
      );
  }

  changePassword({ oldPassword, newPassword }: { oldPassword: string, newPassword: string }): Observable<string> {
    return from(AmplifyAuth.updatePassword({ oldPassword, newPassword }))
      .pipe(
        catchError(this.handleError('Change Password', null))
      );
  }

  updateUserInfo(userInfo: Partial<CognitoUserInterface>): Observable<CognitoUserInterface> {
    return this.updateCognitoUserData(userInfo)
      .pipe(
        catchError(this.handleError('Update User Profile', null)
        )
      );
  }

  switchBusiness(businessProfileId: number | string): Observable<CognitoUserInterface> {
    const updateData = { 'custom:currentBizProfileId': String(businessProfileId) };

    return from(AmplifyAuth.updateUserAttributes({ userAttributes: updateData }))
      .pipe(
        switchMap(() => this.startNewSession()),
        catchError(this.handleError('Switch Business', null))
      );
  }

  startNewSession(fromCache = false): Observable<any> {
    return defer(() => from(
      AmplifyAuth.fetchAuthSession({ forceRefresh: !fromCache })
    ))
      .pipe(
        switchMap(this.setUserSession()),
        tap(this.loadBusinessConfig())
      );
  }

  getAuthenticatedUserInfo(): Observable<CognitoUserInterface> {
    // @ts-ignore
    return from(AmplifyAuth.fetchUserAttributes());
  }

  verifyUpdatedEmail(code: string): Observable<Partial<UserInterface>> {
    const updateData = { 'custom:emailChangeRequest': '' };
    return from(AmplifyAuth.confirmUserAttribute({ userAttributeKey: 'email', confirmationCode: code }))
      .pipe(
        switchMap(() => this.updateCognitoUserData(updateData)),
        catchError(this.handleError('Verify Updated Email', null))
      );
  }

  cancelEmailVerification(): Observable<Partial<UserInterface>> {
    const updateData = { 'custom:emailChangeRequest': '' };
    return this.updateCognitoUserData(updateData).pipe(
      catchError(this.handleError('Update User Profile', null))
    );
  }

  private loadBusinessConfig() {
    return () => {
      if (this.businessProfileService.businessProfile?.business_profile_id) {
        this.salesTaxConfigService.fetchSalesTaxConfig()
          .subscribe({ error: console.error });
      }
    };
  }

  private loadAndCacheUserData(): Observable<any> {
    return zip(
      this.businessProfileService.bypassCache.getBusinessProfile(),
      this.businessProfileService.bypassCache.getBusinessProfiles(),
      this.referenceDataService.fetchReferenceData()
    ).pipe(
      catchError(() => throwError(() => new Error(
        'Application is currently down, our engineers have been notified and are working on it. Please try again later or visit help.atomictax.com'
      ))),
      tap({ error: () => this.logoutUser() })
    );
  }

  private updateCognitoUserData(updateData: Partial<CognitoUserInterface>) {
    // @ts-ignore
    return from(AmplifyAuth.updateUserAttributes({ userAttributes: updateData }))
      .pipe(
        switchMap(() => this.updateLocalUserData(updateData)
        )
      );
  }

  private updateLocalUserData(data: Partial<CognitoUserInterface>): Observable<Partial<CognitoUserInterface>> {
    const currentUserData = this.getUserData();
    return this.handleAuthSuccess({ ...currentUserData, ...data })
      .pipe(
        switchMap(() => this.getAuthenticatedUserInfo())
      );
  }

  private conceiveDisabledUser() {
    return (err: any) => {
      return err.message === 'User is disabled.'
        ? throwError(() => new Error('Invalid username or password')) // do not disclose
        : throwError(() => err); // continue to handler
    };
  }

  private redirectUnconfirmedUser(user: any) {
    return (err: any) => {
      if (err.message === 'User is not confirmed.') {
        this.router.navigate([`/user-verification/${encodeURIComponent(user.email).replace(/\./g, '%2E')}`]);
      }
      return throwError(() => err); // continue to handler
    };
  }

  private checkForNextSteps(email: string) {
    return (loginData: SignInOutput): boolean => {
      switch (loginData.nextStep.signInStep) {
        case 'DONE':
          return true;

        case 'RESET_PASSWORD':
          this.passwordResetEmail = email;
          this.router.navigateByUrl('/forgot-password');
          return false;

        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          this.router.navigateByUrl('/complete-invite');
          return false;

        case 'CONFIRM_SIGN_UP':
          this.router.navigateByUrl(`/user-verification/${encodeURIComponent(email).replace(/\./g, '%2E')}`);
          return false;

        default:
          return false;
      }
    };
  }
  
  private setSentryUser(user: CognitoUserInterface): void {
    Sentry.setUser({ email: user.email });
  }

  private setUserSession() {
    return ({ tokens }: AuthSession) => {
      if (!tokens) {
        return throwError(() => new Error('No tokens found'));
      }

      const { ...userData } = tokens.idToken.payload as unknown as CognitoUserInterface;

      return this.handleAuthSuccess({
        ...userData,
        token: tokens.idToken.toString()
      })
        .pipe(
          tap(() => this.setSentryUser(userData)),
          tap(() => this.analyticsManagerService.identifyUser(userData))
        );
    };
  }
}
