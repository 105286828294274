import { Component, Input } from '@angular/core';
import { StateReport } from '@shared/meta-data/sales-tax-report.meta';

@Component({
  selector: 'app-state-tax-card',
  templateUrl: './state-tax-card.component.html'
})
export class StateTaxCardComponent {
  @Input() collecting: boolean;
  @Input() report: CodeName
}

interface CodeName extends StateReport {
  code: string;
  name: string;
}