const Products = {
  Reporting: 'Reporting',
  Register: 'Register',
  Filing: 'Filing'
};
const ProductsMetadata = {
  Reporting: 'REPORTING',
  Register: 'REGISTRATION',
  Filing: 'US-FILING'
};
module.exports = {
  Products,
  ProductsMetadata
};