import { Pipe, PipeTransform } from '@angular/core';
import { PricingUtils } from '@app/utils/pricing-util/pricing.util';

@Pipe({
  name: 'filterFrequencyByPrices',
})
export class FilterFrequencyByPricesPipe implements PipeTransform {
  transform(frequency: string): string {
    return PricingUtils.getCapitalizedFrequency(frequency);
  }
}