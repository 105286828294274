import { Pipe, PipeTransform } from '@angular/core';
import { EXEMPTIONS } from '@app/utils/constants';

@Pipe({
  name: 'exemptionType'
})
export class ExemptionTypePipe implements PipeTransform {

  transform(value: string): string {
    const exemptionType = value.toUpperCase()
    return EXEMPTIONS[exemptionType];
  }

}
