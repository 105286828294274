import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingToggle = new Subject<boolean>();

  setLoadingToggle(flag: boolean) {
    this.loadingToggle.next(flag);

    if (flag) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  getLoadingToggle() {
    return this.loadingToggle.asObservable();
  }
}
