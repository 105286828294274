import { Pipe, PipeTransform } from '@angular/core';
import { AUTO_FILING_STATUSES } from '@shared/meta-data';

@Pipe({
  name: 'statusColor',
})
export class StateStatusColorPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case AUTO_FILING_STATUSES.PROCESSING:
        return 'warning';
      case AUTO_FILING_STATUSES.PENDING_PAYMENT:
        return 'secondary';
      case AUTO_FILING_STATUSES.REGISTERED:
        return 'success';
      case AUTO_FILING_STATUSES.ACTIVE:
        return 'primary';
      default:
        return 'secondary';
    }
  }
}
