import { Injectable } from '@angular/core';
import { STORE_KEYS } from '@app/utils/constants';
import { StorageService } from '@shared/services/storage/storage.service';
import { STATES } from '@shared/components/address-list/address-list-form.interface';
import { SalesTaxConfig } from '@shared/meta-data';

@Injectable({
  providedIn: 'root'
})
export class TaxConfigStore {

  constructor(private storageService: StorageService) {

  }

  saveSalesTaxConfig(value: SalesTaxConfig, saveObserver = false): SalesTaxConfig {
    return this.storageService.save(STORE_KEYS.SALES_TAX_CONFIG, value, saveObserver)
  }

  retrieveAllTaxConfig(): SalesTaxConfig {
    return this.storageService.get(STORE_KEYS.SALES_TAX_CONFIG)
  }

  retrieveTaxConfigForState(state: STATES) {
    return this.retrieveAllTaxConfig()?.[state] || [];
  }
}
