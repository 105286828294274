import { Pipe, PipeTransform } from '@angular/core';
import { PricingUtils } from '@app/utils/pricing-util/pricing.util';
import { SubscriptionTier } from '@shared/meta-data';

@Pipe({
  name: 'filterAmountByPrices',
})
export class FilterAmountByPricesPipe implements PipeTransform {
  transform(frequency: string, prices: SubscriptionTier[]): number {
    const price = PricingUtils.getPriceForFrequency( prices, frequency);
    return price ? price.unit_amount : 0;
  }
}
