<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
  <div class="align-items-center">
    <div class="ml-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
            <li class="breadcrumb-item" *ngIf="!last">
              <a [routerLink]="url.url">
                <span *ngIf="!url?.icon">{{ url.title }}</span>
                <i aria-hidden="true" *ngIf="url?.icon" class="fas fa-{{ url.icon }}"></i>
              </a>
            </li>
            <li class="breadcrumb-item active" *ngIf="last">{{ url.title }}</li>
          </ng-template>
        </ol>
      </nav>
    </div>
    <h3 class="page-title">{{ pageInfo?.title }}</h3>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
