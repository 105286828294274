<div class="page-container pt-3">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="alert alert-soft-success d-flex  align-items-center" role="alert">
                        <i class="material-icons me-3">check</i>
                        <div class="text-body"><strong>{{title}}</strong>
                            <br>{{body}}
                        </div>
                    </div>
                </div>

                <div class="card-footer text-center border-0">
                    <ng-content></ng-content>
                </div>

            </div>
        </div>
    </div>
</div>