<section class="section-border border-primary">
    <div class="position-absolute top-0 left-0 mt-3 ms-3">
        <a [routerLink]="['/']" class="navbar-brand flex-column mb-2 align-items-center mr-0" style="min-width: 0">
            <img alt="login logo" class="login-logo" src="assets/images/logo-text.svg">
          </a>
	</div>

	<div class="container d-flex flex-column">
		<div class="row align-items-center justify-content-center gx-0 min-vh-100">
			<div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
                @if(downloading && !error){
                    <h1 class="display-4 fw-bold text-center d-flex">
                        Downloading 
                        <span class="mdi mdi-cisco-webex mdi-spin"></span>
                    </h1>
                }
                @else if (error) {
                    <p class="mb-1 text-center text-danger">
                        Download Failed ({{ error }})  <span class="mdi mdi-alert"></span>
                    </p>
    
                    <div class="text-center">
                        <a (click)="retryDownload()" class="btn btn-primary" href="javascript:void(0)">
                            Retry
    
                            @if(downloading){
                                <i class="mdi mdi-cisco-webex mdi-spin" aria-hidden="true"></i>
                            }   
                        </a>
                    </div>
                }

                @else {
                    <h1 class="display-4 fw-bold text-center">
                        Success
                        <span class="mdi mdi-check-circle text-success"></span>
                    </h1>
                }
			</div>
		</div>
	</div>
</section>
