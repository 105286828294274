import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CurrentUserInfo } from '@shared/meta-data/user.meta';
import { WebsocketConfigService } from '@app/websocket/websocket-config.service';
import { AppBanner } from '@shared/meta-data/app-banner.meta';
import { environment } from '../../../../environments/environment';

export interface Notifications {
  is_read: boolean,
  notification_id: string,
  message: string,
  title: string,
  time: string,
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {
  FEATURE_FLAG = environment.FEATURE_FLAG;

  @Output() toggleSidebar = new EventEmitter<void>();
  @Output() navBarToggle = new EventEmitter<void>();
  @Output() logOut = new EventEmitter<void>();
  @Input() user: CurrentUserInfo = {};
  @Input() banner?: AppBanner;

  @HostBinding('class') classes = 'd-flex justify-content-between';

  notifications: Array<Notifications> = [];

  constructor(
    translate: TranslateService,
    private readonly websocketConfig: WebsocketConfigService
  ) {
    translate.setDefaultLang('en');
    websocketConfig.webSocket.onopen = () => {
      websocketConfig.listNotifications();
      websocketConfig.notificationsSubject
        .subscribe((notifications: Array<Notifications>) => {
          this.notifications = notifications;
        });
    };
  }

  readNotifications(notification_ids: string[]): void {
    this.websocketConfig.readNotifications(notification_ids);
  }

  getUnreadNotifications(): Array<Notifications> {
    return this.notifications.filter((n) => !n.is_read);
  }
}
