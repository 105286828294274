import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { RouteInfo } from './sidebar.metadata';
import { SidebarService } from '@shared/components/sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  showMenu = '';
  showSubMenu = '';
  sidebarnavItems: RouteInfo[] = [];
  path = '';


  @Output() toggleSidebar = new EventEmitter<boolean>();

  constructor(
    private menuService: SidebarService,
    private router: Router
  ) {
    this.menuService.items.subscribe(menuItems => {
      this.sidebarnavItems = menuItems;

      // Active menu
      this.sidebarnavItems.filter(m => m.submenu.filter(
        (s) => {
          if (s.path === this.router.url) {
            this.path = m.title;
          }
        }
      ));
      this.addExpandClass(this.path, false);
    });
  }

  addExpandClass(element: string, shouldToggleSidebar = true): void {
    if (shouldToggleSidebar) {
      this.toggleSidebar.emit();
    }

    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any): void {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
