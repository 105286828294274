import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_SCHEDULES } from '@app/utils/constants';

@Pipe({
  name: 'frequencyTransform'
})
export class FrequencyTransformPipe implements PipeTransform {

  transform(value: string): string {
      switch (value?.toLowerCase()) {
        case PAYMENT_SCHEDULES.ANNUALLY.toLowerCase():
        case PAYMENT_SCHEDULES['FISCAL-ANNUALLY'].toLowerCase():
          return 'runs every year';

        case PAYMENT_SCHEDULES['SEMI-ANNUALLY'].toLowerCase():
          return 'runs every 6 months';

        case PAYMENT_SCHEDULES.QUARTERLY.toLowerCase():
        case PAYMENT_SCHEDULES['QUARTERLY-PREPAY'].toLowerCase():
          return 'runs every 3 months';

        case PAYMENT_SCHEDULES.MONTHLY.toLowerCase():
          return 'runs every month';

        case PAYMENT_SCHEDULES['SEMI-MONTHLY'].toLowerCase():
          return 'runs twice a month';

        case PAYMENT_SCHEDULES['BI-MONTHLY'].toLowerCase():
          return 'runs every 2 months';

        default:
          return '';
      }
    }
}
