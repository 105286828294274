import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
})
export class AmountComponent {

  @Input() amount: number;
  @Input() currency = 'USD';

}
