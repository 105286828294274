import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {
  @Input() listItems: NavigatorItem[] = []
  @Input() currentItem: NavigatorItem

  @Output() navigate = new EventEmitter<NavigatorEmitterItem>()


  get currentItemIndex(): number {
    return this.listItems.findIndex((item) => item.title === this.currentItem?.title)
  }

  get currentItemIsFirstItem(): boolean {
    return this.hasItem && this.listItems[0].title === this.currentItem.title
  }

  get currentItemIsLastItem(): boolean {
    return this.hasMoreThanOneItem && this.listItems[this.listItems.length - 1].title === this.currentItem.title
  }

  get currentItemIsOnlyItem(): boolean {
    return this.hasItem && this.listItems.length === 1
  }

  get hasItem(): boolean {
    return this.listItems.length > 0
  }

  get hasMoreThanOneItem(): boolean {
    return this.listItems.length > 1
  }


  ngOnInit(): void {
    // Set the first item as default if the current item is not passed in
    if (!this.currentItem) {
      this.currentItem = this.listItems[0];
    }
  }

  navigator(direction: NavDirection) {
    const currentIndex = direction === 'next' ? this.currentItemIndex + 1 : this.currentItemIndex - 1
    this.currentItem = this.listItems[currentIndex]

    this.navigate.emit({ direction, item: this.currentItem })
  }


}

export interface NavigatorItem<T = any> {
  /**
   *  This is the data associated with the current displayed title. When the navigator navigates to this current item, the data is emitted 
   * */
  data: T

  /**
   * This is the property whose value is displayed on the UI. It should be unique
   */
  title: string

}

export interface NavigatorEmitterItem<T = any> {
  direction: NavDirection,
  item: NavigatorItem<T>
}

export type NavDirection = 'previous' | 'next'