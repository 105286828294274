<div class="card">
	<div class="card-body row align-items-center">
		<div class="col-6 col-sm-4">
			<small>Next Filing Due:</small>
			<br>
			<strong class="h4">{{ report.payment_schedule.filingDate | isoDate:'MM-DD-YYYY' | date:'MMMM dd, YYYY' }}</strong>
			<br>
			<small>
				{{ report.payment_schedule | paymentSchedule }}
				<span *ngIf="report.has_nexus" role="button"
							routerLink="/account-settings/state-nexus/{{report.code}}"
							ngbTooltip="Change filling frequency" class="mdi mdi-wrench text-info">
				</span>
			</small>
		</div>

		<div class="col-sm-4 d-none d-sm-block text-center">
			<p *ngIf="!collecting" class="small">
				Expected Sales Tax Due
				<span class="mdi mdi-alert-circle text-warning"></span>
			</p>
			<p *ngIf="collecting" class="small">
				Actual Sales Tax Collected
				<span class="mdi mdi-check-circle text-success"></span>
			</p>

			<p>
				<app-amount [amount]="report.reports.total_sales_tax"
										class="h2 text-{{ collecting ? 'success' : 'warning' }}"></app-amount>
			</p>

			<a [routerLink]="['/sales-report', report.code]"
				 [queryParams]="{start_date:report.payment_schedule.start, end_date:report.payment_schedule.end}"
				 class="btn btn-xs btn-outline-{{ collecting ? 'success' : 'warning'}}" fragment="actual">
				<span class="mdi mdi-file-chart"></span>
				Sales Tax Report
			</a>
		</div>

		<div class="col-6 col-sm-4 text-center">
			<div class="row justify-content-center">
				<div class="d-flex flex-column align-items-center align-self-center">
					<p class="h4">{{ report.name }}</p>

					<div class=" d-none d-sm-block">
						<button *ngIf="!report.enrolled_in_autofilling"
										class="btn btn-xs btn-outline-primary mb-2" routerLink="../autofiling">
							<span class="mdi mdi-checkbox-marked-outline"></span>
							Enroll in AutoFiling
						</button>
					</div>

					<div class="d-block d-sm-none">
						<ng-container [ngTemplateOutlet]="mobileLayout"></ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #mobileLayout>
	<p class="mb-1 clearfix">
		<app-amount [amount]="report.reports.total_sales_tax" class="h2 text-{{ collecting ? 'success' : 'warning' }}" />
		@if (collecting) {
			<span class="mdi mdi-check-circle text-success" ngbTooltip="Actual Sales Tax Collected"
						triggers="focus click" [autoClose]="true"></span>
		} @else {
			<span class="mdi mdi-alert-circle text-warning" ngbTooltip="Expected Sales Tax Due"
						triggers="focus click" [autoClose]="true"></span>
		}
	</p>
	<div class="btn-group">
		<button [routerLink]="['/sales-report', report.code]"
						[queryParams]="{start_date:report.payment_schedule.start, end_date:report.payment_schedule.end}"
						class="btn btn-xs btn-outline-{{ collecting ? 'success' : 'warning'}}" fragment="actual"
						style="margin-right: 0 !important;">
			<span class="mdi mdi-file-chart"></span>
			Sales Tax Report
		</button>

		<div class="btn-group" ngbDropdown role="group">
			<button ngbDropdownToggle type="button" class="btn btn-xs btn-outline-secondary dropdown-toggle">
				<span class="visually-hidden">Toggle Dropdown</span>
			</button>
			<div class="dropdown-menu p-0" ngbDropdownMenu>
				<button class="btn btn-outline-primary btn-xs w-100" type="button" routerLink="../autofiling">
					<span class="mdi mdi-checkbox-marked-outline"></span>
					Enroll in AutoFiling
				</button>
			</div>
		</div>
	</div>
</ng-template>
