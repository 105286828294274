import { HttpErrorResponse } from '@angular/common/http';
import { iif, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import Helpers from '@app/utils/helpers';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> (operation?: string, result?: T, options?: ErrorHandlerOptions) => (error: HttpErrorResponse) => Observable<T>;

/* Options passed into the exported error handler */

export class ErrorHandlerOptions {
  showToast = true;
}

export const ErrorHandlerOptionsDefault = new ErrorHandlerOptions();

/** Handles HttpClient errors */
@Injectable({ providedIn: 'root' })
export class HttpErrorHandler {
  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly modalService: NgbModal

  ) {
  }

  isBlobError(err: any) {
    return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json';
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    const reader: FileReader = new FileReader();
    const obs = new Observable<HttpErrorResponse>((observer) => {
      reader.onloadend = () => {
        observer.next(new HttpErrorResponse({
          ...err,
          error: JSON.parse(reader.result as string)
        }));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T>
  (operation = 'operation', result = false as T, options?: ErrorHandlerOptions) => this.handleError(serviceName, operation, result, options);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param _serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - value to return as the observable result; cancels subscription by default; throws error if `null`;
   * @param options
   */
  handleError<T>(_serviceName = '', operation = 'operation', result: T, options = ErrorHandlerOptionsDefault): any {

    return (e: HttpErrorResponse): Observable<T> => {
      const errorHandlerObs = of(e).pipe(
        switchMap((e) => {
          if (this.isBlobError(e)) {
            return this.parseErrorBlob(e);
          }
          return of(e);
        }),
        tap((e) => {
          let message: string;
          // Error throw logic based on API structure
          switch (true) {
            case e.error instanceof ProgressEvent: {
              message = 'Server Unavailable';
              break;
            }

            case (Array.isArray(e.error?.errors)): {
              message = Helpers.snakeCaseToString(e.error.errors.map(err => err.message).join('<br>'));
              break;
            }

            case (typeof e.error?.body === 'string'): {
              message = e.error.body;
              break;
            }

            case (typeof e.error?.error?.message === 'string'): {
              message = e.error.error.message;
              break;
            }

            case (typeof e.error?.message === 'string'): {
              message = e.error.message;
              break;
            }

            default: {
              message = e.message;
            }
          }

          if (e.status === 401 && e.url?.startsWith(AppConfig.API_URL)) {
            message = 'Authentication Failed';
            this.logout();
          }

          if (e.status !== 404 && options.showToast) {
            // Throw an Error Toast
            this.toastr.error(message, operation);
          }
        }),
        switchMap((e) => {
          return iif(
            () => result === null,
            throwError(() => e),
            of(result).pipe(takeWhile(r => r !== false))
          );
        })
      );


      return errorHandlerObs;
    };

  }

  logout(): void {
    localStorage.clear();
    this.modalService.dismissAll()
    this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
  }
}
