import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToKebap'
})
export class StringToKebapPipe implements PipeTransform {

  transform(value: string): unknown {
    if (value == null) {
      return '';
    }
   
    return value.trim().replace(/\s+/g, '-').toLowerCase();
  }

}
