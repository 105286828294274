import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_SCHEDULES } from '@app/utils/constants';
import { StateReportPaymentSchedule } from '@shared/meta-data';


@Pipe({
  name: 'paymentSchedule'
})
export class PaymentSchedulePipe implements PipeTransform {

  transform(value: StateReportPaymentSchedule, ...args: unknown[]): string {
    const schedule = value.payment_schedule

    return PAYMENT_SCHEDULES[schedule]
  }

}
