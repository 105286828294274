import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
})
export class CalendlyComponent implements OnInit, AfterViewInit, OnDestroy {
  calendlyId: string;
  private calendlyInstance: any;

  @Input({ required: true }) calendlyUrl: string;

  @ViewChild('calendlyContainer', { static: true }) calendlyContainer: ElementRef;

  ngOnInit(): void {
    this.calendlyId = this.generateCalendlyId();
  }

  ngAfterViewInit(): void {
    this.loadCalendly();
  }

  loadCalendly() {
    if (window.Calendly) {
      this.createCalendlyWidget();
    } else {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      script.onload = () => {
        this.createCalendlyWidget();
      };
      document.body.appendChild(script);
    }
  }

  private createCalendlyWidget() {
    this.calendlyInstance = window.Calendly.initInlineWidget({
      url: this.calendlyUrl,
      parentElement: this.calendlyContainer.nativeElement,
      prefill: {},
      utm: {},
    });
  }

  generateCalendlyId() {
    return Math.random().toString(36).substring(2);
  }

  ngOnDestroy(): void {
    if (this.calendlyInstance) {
      this.calendlyInstance.destroy();
    }
  }
}
