import { COUNTRIES } from '@shared/components/address-list/address-list-form.interface';

export interface CurrentUserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  businessName?: string;
  hasMultipleBusinesses?: boolean;
}

export interface CognitoUserInterface {
  email: string;
  email_verified: boolean;
  phone_number_verified: boolean;
  phone_number: string;
  given_name: string;
  family_name: string;
  sub: string;

  'custom:UserType'?: string;
  'custom:emailChangeRequest'?: string;
  'custom:shopifyBilling'?: string;
  'custom:currentBizProfileId'?: string;
}

export interface UserInterface extends CognitoUserInterface {
  token: string;
}

export class AtomickitUser {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
  full_name: string;
  country: COUNTRIES;
  user_type: string;
  shopify_billing: boolean;

  constructor() {
    this.full_name = '';
  }
}

export class User implements UserInterface {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
  token: string;

  'custom:UserType'?: string;
  'custom:emailChangeRequest'?: string;
  'custom:shopifyBilling'?: string;

  constructor(json: UserInterface) {
    Object.assign(this, json);
  }
}
