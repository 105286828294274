import { Amplify, fetchAuthSession } from '@aws-amplify/core';
import { assertTokenProviderConfig, AuthAction } from '@aws-amplify/core/internals/utils';
import { updateUserAttributes as updateUserAttributes$1 } from '../utils/clients/CognitoIdentityProvider/index.mjs';
import { assertAuthTokens } from '../utils/types.mjs';
import { getRegion } from '../utils/clients/CognitoIdentityProvider/utils.mjs';
import { toAttributeType } from '../utils/apiHelpers.mjs';
import { getAuthUserAgentValue } from '../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Updates user's attributes while authenticated.
 *
 * @param input - The UpdateUserAttributesInput object
 * @returns UpdateUserAttributesOutput
 * @throws - {@link UpdateUserAttributesException}
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
const updateUserAttributes = async input => {
  const {
    userAttributes,
    options
  } = input;
  const authConfig = Amplify.getConfig().Auth?.Cognito;
  const clientMetadata = options?.clientMetadata;
  assertTokenProviderConfig(authConfig);
  const {
    tokens
  } = await fetchAuthSession({
    forceRefresh: false
  });
  assertAuthTokens(tokens);
  const {
    CodeDeliveryDetailsList
  } = await updateUserAttributes$1({
    region: getRegion(authConfig.userPoolId),
    userAgentValue: getAuthUserAgentValue(AuthAction.UpdateUserAttributes)
  }, {
    AccessToken: tokens.accessToken.toString(),
    ClientMetadata: clientMetadata,
    UserAttributes: toAttributeType(userAttributes)
  });
  return {
    ...getConfirmedAttributes(userAttributes),
    ...getUnConfirmedAttributes(CodeDeliveryDetailsList)
  };
};
function getConfirmedAttributes(attributes) {
  const confirmedAttributes = {};
  Object.keys(attributes)?.forEach(key => {
    confirmedAttributes[key] = {
      isUpdated: true,
      nextStep: {
        updateAttributeStep: 'DONE'
      }
    };
  });
  return confirmedAttributes;
}
function getUnConfirmedAttributes(codeDeliveryDetailsList) {
  const unConfirmedAttributes = {};
  codeDeliveryDetailsList?.forEach(codeDeliveryDetails => {
    const {
      AttributeName,
      DeliveryMedium,
      Destination
    } = codeDeliveryDetails;
    if (AttributeName) unConfirmedAttributes[AttributeName] = {
      isUpdated: false,
      nextStep: {
        updateAttributeStep: 'CONFIRM_ATTRIBUTE_WITH_CODE',
        codeDeliveryDetails: {
          attributeName: AttributeName,
          deliveryMedium: DeliveryMedium,
          destination: Destination
        }
      }
    };
  });
  return unConfirmedAttributes;
}
export { updateUserAttributes };
