import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeToString',
})
export class SnakeToStringPipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value) return '';
      return value.replace(/_+/g, ' ');
    }

}
