import { Component, HostListener, inject, OnInit } from '@angular/core';
import { LoadingService } from '@shared/services/loader/loading.service';
import { SideBarType } from '@shared/meta-data/sidebar.meta';
import { AuthService } from '@app/auth/auth.service';
import { CurrentUserInfo } from '@shared/meta-data';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html'
})
export class FullComponent implements OnInit {
  public showSpinner$: Observable<boolean>;
  public isCollapsed = false;
  public innerWidth: any;
  public defaultSidebar: any;
  public showMobileMenu = false;
  public expandLogo = false;
  options: { sidebartype: SideBarType, [key: string]: any } = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // two possible values: vertical, horizontal
    sidebartype: SideBarType.FULL, // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  protected loadingService = inject(LoadingService);
  protected authService = inject(AuthService);

  get userInfo(): CurrentUserInfo {
    return this.authService.userInfo;
  }

  Logo(): void {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit(): void {
    this.showSpinner$ = this.loadingService.getLoadingToggle();
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.handleSidebar();
  }

  handleSidebar(): void {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case SideBarType.FULL:
      case SideBarType.ICON_BAR:
        this.options.sidebartype = this.innerWidth < 1170 ? SideBarType.MINI_SIDE_BAR : this.defaultSidebar;
        break;

      case SideBarType.OVERLAY:
        this.options.sidebartype = this.innerWidth < 767 ? SideBarType.MINI_SIDE_BAR : this.defaultSidebar;
        break;

      default:
    }
  }

  toggleSidebarType(): void {
    switch (this.options.sidebartype) {
      case SideBarType.ICON_BAR:
      case SideBarType.FULL:
        this.options.sidebartype = SideBarType.MINI_SIDE_BAR;
        break;

      case SideBarType.OVERLAY:
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case SideBarType.MINI_SIDE_BAR:
        this.options.sidebartype = this.defaultSidebar === SideBarType.MINI_SIDE_BAR ? SideBarType.FULL : this.defaultSidebar;
        break;

      default:
    }
  }

  logOut() {
    this.authService.logoutUser();
  }
}
