import { Injectable } from '@angular/core';
import { STORE_KEYS, STORE_TEMP_KEYS } from '@app/utils/constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // Observable version of the store object
  private observerStore: Map<STORE_KEYS, BehaviorSubject<any>> = new Map();

  // Store for temporary data that doesn't need persistence
  private temporaryStore: Map<STORE_TEMP_KEYS, any> = new Map();

  constructor() {
    this.init();
  }

  init(): void {
    for (let key of Object.keys(localStorage)) {
      // There are some items in the local storage that are not part of the core storage service usage. We need to cater for them.
      if (key in STORE_KEYS) {
        const localStorageData: StoreData<any> | null = JSON.parse(localStorage.getItem(key));
        if (localStorageData.saveObserver) {
          this.saveObservableToObserverStore(key as STORE_KEYS, localStorageData.value);
        }
      }
    }
  }


  /* Saving Methods */
  /**
   * This method saves an item to the store.
   * It also allows you to specify as a third option whether to save the observable version of the item.
   * It defaults to false
   *
   * @template T
   * @param {STORE_KEYS} key
   * @param {T} value
   * @param {boolean} [saveObserver=false]
   * @return {*}  {T}
   * @memberof StorageService
   */
  save<T>(key: STORE_KEYS, value: T, saveObserver: boolean = false): T {
    const localStoreData: StoreData<T> = { value, saveObserver };

    localStorage.setItem(key, JSON.stringify(localStoreData));

    if (saveObserver) {
      this.saveObservableToObserverStore(key, value);
    }

    return value;
  }

  /* Retrieval Methods */
  /**
   *
   * This retrieves the store data in the store.
   * It returns a null if the data doesn't exist.
   *
   * @template T
   * @param {STORE_KEYS} key
   * @return {*}  {T | null}
   * @memberof StorageService
   */
  get<T>(key: STORE_KEYS): T | null {
    const localStorageData: StoreData<T> | null = JSON.parse(localStorage.getItem(key));
    return localStorageData ? localStorageData.value : null;
  }

  delete(key: STORE_KEYS): void {
    localStorage.removeItem(key);
  }

  /**
   *
   * Fetches the observable version of the item in the store.
   * it returns an observable of null if the item is not found in the store
   * Please note that the {saveObserver} option must have been set to true while saving the item to the store
   * @template T
   * @param {STORE_KEYS} key
   * @return {*}  {(Observable<T | null>)}
   * @memberof StorageService
   */
  getObs<T>(key: STORE_KEYS): Observable<T | null> {
    return this.observerStore.get(key) || new Observable(null);
  }

  storeTemporaryData(key: STORE_TEMP_KEYS, value: any) {
    this.temporaryStore.set(key, value);
  }

  removeTemporaryData(key: STORE_TEMP_KEYS) {
    this.temporaryStore.delete(key);
  }

  retrieveTemporaryData<T>(key: STORE_TEMP_KEYS): T {
    return this.temporaryStore.get(key) || null;
  }

  static clearStoreKeys() {
    Object
      .values(STORE_KEYS)
      .filter((v) => isNaN(Number(v)))
      .forEach((key) => localStorage.removeItem(key));
  }

  private saveObservableToObserverStore<T>(key: STORE_KEYS, value: T) {
    this.observerStore.has(key) ? this.observerStore.get(key).next(value) : this.observerStore.set(key, new BehaviorSubject(value));
  }
}


interface StoreData<T> {
  value: T,
  saveObserver: boolean
}
