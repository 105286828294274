  <div class="modal-header">
    <a (click)="close()"class="navbar-brand">
      <img alt="homepage" class="light-logo" src="assets/images/logo-text.svg" />
    </a>

    <button type="button" class="btn btn-secondary" data-test-id="close-btn" aria-label="Close" (click)="close()">Close</button>
	</div>

	<div class="modal-body">
		<div id="linked-provider-processing" class="container my-4">
      <div class="row justify-content-md-center">
        <div class="col-md-8">
          <h1 class="mb-4 text-center">Data synchronization {{ isSyncComplete ? 'complete' : 'in progress'}}!</h1>
          <p class="mb-4 fs-5">
            We're diligently working behind the scenes to pull all your data from to ensure we deliver the most
            accurate and up-to-date information to you. <br /><br />
            This process is essential for providing the high-quality service you
            expect. Please bear with us, as the time it takes to complete this task
            depends on the amount of data you have. Rest assured, we're doing
            everything we can to make this as quick and seamless as possible. Thank
            you for your patience and understanding.
          </p>
        </div>
    
        <div class="col-md-8">
          <div class="row justify-content-md-center">
            @if (isLoading$ | async) {
              <ng-template [ngTemplateOutlet]="loadingRef"></ng-template>
            }
            @else {
               <ng-container *ngIf="linkedAccountsMap$ | async as linkedAccountsMap">
                @for(account of getEntries(linkedAccountsMap); track account[0]._token_id;) {
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="card border-{{account[1].status === linkedAccountEventStatus.COMPLETED ? 'success' : 'warning' }}">
                      <div class="card-body">
                        <h5 class="fw-bold">{{account[0]._provider}}</h5>
                        <div class="d-flex mt-4">
                          <span class="text-muted"> Processed transactions: </span>
                          <span class="ms-auto">{{ account[0]._pull_count }}</span>
                        </div>
                        <p class="mt-2">
                          <ngb-progressbar
                            [striped]="account[1].status === linkedAccountEventStatus.COMPLETED? false : true"
                            type="{{account[1].status === linkedAccountEventStatus.COMPLETED ? 'success' : 'warning' }}"
                            [value]="account[1].status === linkedAccountEventStatus.COMPLETED ? 100 : account[0]._pull_count"
                            height="0.5rem" 
                            [animated]="account[1].status === linkedAccountEventStatus.COMPLETED? false : true">
                          </ngb-progressbar>
                        </p>
                      </div>
                    </div>
                  </div>
                }
               </ng-container>
            }
          </div>
        </div>
    
        <div class="col-12">
          <app-calendly calendlyUrl="https://calendly.com/atomictax/sales-tax-demo?primary_color=304398"></app-calendly>
        </div>
    
        <div class="col-md-8">
          <div class="row">
            <h1 class="mb-4 text-center">These blogs will give you more insight</h1>
            @for(article of articles; track article.title;) {
              <div class="col-md-4 mb-4">
                <a [href]="article?.link" class="card-link">
                  <div class="card h-100">
                    <div class="card-body">
                      <h5 class="card-title fw-bold">{{ article?.title }}</h5>
                      <p class="card-text text-ellipsis fs-6">{{ article?.description }}</p>
                    </div>
                  </div>
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
	</div>

  <ng-template #loadingRef>
    @for (item of [1,2,3]; track item) {
      <div class="col-lg-4 col-sm-6 col-12 ">
        <ngx-skeleton-loader [theme]="{ height: '12em'}"></ngx-skeleton-loader>
      </div>
  }
  </ng-template>