import { Component, Input } from '@angular/core';
import { StateReport } from '@shared/meta-data/sales-tax-report.meta';

@Component({
  selector: 'app-tax-table',
  templateUrl: './tax-table.component.html',
})
export class TaxTableComponent {
  @Input() report: StateReport;

  heading: any;
  isBreakdownCollapsed: boolean = true;

  private _state: 'actual' | 'expected';

  get state(): 'actual' | 'expected' {
    return this._state;
  }

  @Input() set state(value: 'actual' | 'expected') {
    this._state = value;

    // set other things...
    this.heading = value === 'actual' ? 'Tax Collected' : value === 'expected' ? 'Expected Tax Due' : '';
  }
}
