export interface ConfirmationDialogConfig {
  type: 'danger' | 'warning' | 'success' | 'secondary';
  title: string;
  body: string;
  positiveActionText: string;
  negativeActionText: string;
}

export class ConfirmationDialogDefault implements ConfirmationDialogConfig {
  type: 'secondary' = 'secondary';
  body = 'Are you sure you want to carry out this action?';
  title = 'Confirm Action';
  positiveActionText = 'Ok';
  negativeActionText = 'Cancel';
}
