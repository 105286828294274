import { environment } from 'src/environments/environment';
import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Menu',
    icon: 'dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    label: '',
    labelClass: '',
    submenu: [],
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'home',
    class: '',
    label: '',
    labelClass: 'side-badge badge badge-info',
    extralink: false,
    submenu: [],
  },
  {
    path: '/sales-transactions',
    title: 'Transactions',
    icon: 'receipt',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/economic-nexus',
    title: 'Economic Nexus',
    icon: 'album',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/autoregister',
    title: 'AutoRegister',
    icon: 'auto-upload',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/autofiling',
    title: 'AutoFiling',
    icon: 'file-restore',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/autofiling-old',
    title: 'AutoFiling (Old)',
    icon: 'file-restore',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
    canView: environment.FEATURE_FLAG.autofiling_v2,
  },
  {
    path: '/filing-history',
    title: 'Filing History',
    icon: 'history',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
];
