@if((newEventsWhereProcessed$ | async) && canShowProviderProcessing){
  <div
    class="px-4 py-2 bg-{{ (newEventsWhereProcessed$ | async) && (isSyncComplete$ | async)  ? 'success' : 'warning'}}-subtle border border-opacity-10 w-100"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-md-block d-none"></div>
      <div class="d-md-flex align-items-md-center gap-4">
        <h4 class="mb-2 mb-md-0 fw-bold">Data synchronization {{(newEventsWhereProcessed$ | async) && (isSyncComplete$ | async) ?'Complete' : 'in progress'}}</h4>

        <span class="d-md-block d-none">|</span>

        <p class="mb-0">
          Click
          <a
            href="javascript:void(0)"
            class="pe-auto"
            (click)="openFullScreenDialog()"
            >here</a
          >
          to view the full breakdown.
        </p>
      </div>

      <div>
        @if((newEventsWhereProcessed$ | async) && (isSyncComplete$ | async)){
          <span class="mdi mdi-close"  matRipple role="button" (click)="closeBanner()"></span>
        }
      </div>
    </div>
  </div>
}
