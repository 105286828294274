<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
	<li class="nav-item d-none d-lg-block">
		<a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
			 href="javascript:void(0)">
			<i class="ti-menu"></i>
		</a>
	</li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">

	<li *ngIf="banner" class="d-flex align-items-center gap-2">
		<a [routerLink]="banner.link"
			 class="d-inline-flex px-2 py-1 fw-semibold text-danger bg-warning bg-opacity-10 border border-opacity-10 rounded-2 gap-2"
			 href="javascript:void(0)" ngbTooltip="{{ banner.hint }}"
			 placement="bottom">
			 <span class="d-block d-md-none text-nowrap overflow-auto" style="max-width: 175px;">{{ banner.message }}</span>
			 <span class="d-none d-md-block">{{ banner.message }}</span>
			
			<span class="mdi mdi-chevron-right"></span>
		</a>
	</li>

	<!-- ============================================================== -->
	<!-- Notification -->
	<!-- ============================================================== -->
	<li class="nav-item dropdown" ngbDropdown placement="bottom-right">
		<a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle waves-effect waves-dark"
			 data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>
      <span *ngIf="getUnreadNotifications().length > 0"
						class="badge bg-primary">{{ getUnreadNotifications().length }}</span>
			<i class="mdi mdi-bell font-24"></i>
			<div *ngIf="false" class="notify"><span class="heartbit"></span> <span class="point"></span></div>
		</a>
		<div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
			<ul class="list-style-none">
				<li class="border-bottom">
					<div class="drop-title bg-light-primary text-dark">Notifications</div>
				</li>
				<li>
					<ng-scrollbar [sensorDebounce]="1" autoHeightDisabled="false" class="message-center notifications">
						<a *ngFor="let notification of notifications" class="message-item" href="javascript:void(0)">
              <span (click)="readNotifications([notification.notification_id])" class="notification-message">
                <h5 class="message-title">{{ notification?.title }}</h5>
                <span [class.fw-bold]="notification.is_read === false" class="mail-desc">{{
									notification?.message }}</span>
                <span class="time">{{ notification?.time }}</span>
              </span>
						</a>
					</ng-scrollbar>
				</li>
				<li>
					<a class="nav-link text-center m-b-5" href="javascript:void(0);">
						<strong>Check all notifications</strong>
						<i aria-hidden="true" class="mdi mdi-arrow-right"></i>
					</a>
				</li>
			</ul>
		</div>
	</li>
	<!-- ============================================================== -->
	<!-- End Notification -->
	<!-- ============================================================== -->


	<!-- ============================================================== -->
	<!-- User profile and search -->
	<!-- ============================================================== -->
	<li>
		<div class="h-100 nav-divider"></div>
	</li>
	<li class="nav-item" ngbDropdown placement="bottom-right">
		<a aria-expanded="false" aria-haspopup="true"
			 class="nav-link text-light fw-semibold-2 waves-effect waves-dark pro-pic" data-toggle="dropdown" href="javascript:void(0)"
			 ngbDropdownToggle>
			{{ user?.businessName }}
			<i aria-hidden="true" class="mdi mdi-chevron-down m-r-5 m-l-5"></i>
		</a>
		<div class="dropdown-menu-right user-dd py-2" ngbDropdownMenu>

			<ng-container *ngIf="FEATURE_FLAG.multi_account_switch && user.hasMultipleBusinesses">
				<a (click)="navBarToggle.emit()" [routerLink]="['/switch']" class="dropdown-item">
					<i aria-hidden="true" class="ti-layers m-r-5 m-l-5"></i> Switch Companies
				</a>

				<div class="dropdown-divider border-2 border-secondary-subtle"></div>
			</ng-container>

			<div class="px-3 py-2">
				<div class="m-l-10">
					<h5 class="mb-0 font-16 fw-bold">
						{{ user?.firstName | titlecase }} {{ user?.lastName }}
					</h5>
					<p class="text-black-50 m-b-0 font-14">{{ user?.email }}</p>
				</div>
			</div>

			<a (click)="navBarToggle.emit()" [routerLink]="['/account-settings']" class="dropdown-item">
				<i aria-hidden="true" class="ti-settings m-r-5 m-l-5"></i> Account Settings
			</a>

			<a (click)="navBarToggle.emit()" class="dropdown-item" href="https://help.atomictax.com/" target="_blank">
				<i aria-hidden="true" class="mdi mdi-help-circle-outline m-r-5 m-l-5"></i> Support
			</a>

			<a (click)="logOut.emit()" class="dropdown-item" href="javascript:void(0)">
				<i aria-hidden="true" class="mdi mdi-logout m-r-5 m-l-5"></i> Logout
			</a>
		</div>
	</li>
	<!-- ============================================================== -->
	<!-- User profile and search -->
	<!-- ============================================================== -->
</ul>
