module.exports = {
  FEATURE_FLAG: {
    DEV: {
      multi_account_switch: true,
      teammates_signup: true,
      amazon_linked_account: true,
      suspend_autofiling: true,
      linked_account_processing: true,
      autofiling_v2: true
    },
    PROD: {
      multi_account_switch: true,
      teammates_signup: true,
      amazon_linked_account: false,
      suspend_autofiling: false,
      linked_account_processing: true,
      autofiling_v2: false
    }
  }
};