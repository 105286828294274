import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { iif, Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { BusinessProfile } from '@shared/meta-data/business-profile.meta';
import { StorageService } from '@shared/services/storage/storage.service';
import { IResponse } from '@shared/meta-data/generic.meta';
import { STORE_KEYS } from '@app/utils/constants';
import { AppConfig } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class BusinessProfileService {
  private readonly handleError: HandleError;
  private shouldClearCache: boolean;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly storage: StorageService,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('Business Profile Service');
  }

  get businessProfile(): BusinessProfile {
    return this.storage.get(STORE_KEYS.BUSINESS_PROFILE_KEY);
  }

  set businessProfile(profile: BusinessProfile) {
    this.storage.save(STORE_KEYS.BUSINESS_PROFILE_KEY, profile);
  }

  get businessProfiles(): BusinessProfile[] {
    return this.storage.get(STORE_KEYS.BUSINESS_PROFILES_KEY);
  }

  set businessProfiles(profiles: Array<BusinessProfile>) {
    this.storage.save(STORE_KEYS.BUSINESS_PROFILES_KEY, profiles);
  }

  get bypassCache(): BusinessProfileService {
    this.shouldClearCache = true;

    return this;
  }

  getBusinessProfile(): Observable<BusinessProfile> {
    this.checkAndClearCache(STORE_KEYS.BUSINESS_PROFILE_KEY);

    return iif(
      () => !!this.businessProfile,
      of(this.businessProfile),
      this.fetchBusinessProfile()
    ).pipe(
      distinctUntilChanged()
    );
  }

  getBusinessProfiles(): Observable<BusinessProfile[]> {
    this.checkAndClearCache(STORE_KEYS.BUSINESS_PROFILES_KEY);

    return iif(
      () => !!this.businessProfiles,
      of(this.businessProfiles),
      this.fetchBusinessProfiles()
    ).pipe(
      distinctUntilChanged()
    );
  }

  updateBusinessProfile(data: BusinessProfile): Observable<any> {
    return this.httpClient.put<IResponse<BusinessProfile>>(`${AppConfig.API_URL}/business-profile`, data)
      .pipe(
        map(({ content, message }) => ({ content, message })),
        catchError(this.handleError('Update Business Profile', null))
      );
  }

  createBusinessProfile(data: Partial<BusinessProfile>): Observable<any> {
    return this.httpClient.post(`${AppConfig.API_URL}/business-profile`, data)
      .pipe(
        catchError(this.handleError('Create Business Profile', null))
      );
  }

  private fetchBusinessProfile(): Observable<BusinessProfile> {
    return this.httpClient.get<IResponse<BusinessProfile>>(`${AppConfig.API_URL}/business-profile`)
      .pipe(
        map(({ content }) => content),
        tap(data => this.businessProfile = data),
        catchError(this.silence404Error()),
        catchError(this.handleError('Fetch Business Profile', null))
      );
  }

  private fetchBusinessProfiles(): Observable<BusinessProfile[]> {
    return this.httpClient.get<IResponse<BusinessProfile[]>>(`${AppConfig.API_URL}/business-profiles`)
      .pipe(
        map(({ content }) => content),
        tap(data => this.businessProfiles = data),
        catchError(this.silence404Error([])),
        catchError(this.handleError('Fetch Business Profiles', []))
      );
  }

  private silence404Error(result = null) {
    return (err: any) => {
      return err.status === 404
        ? of(result)
        : throwError(() => err);
    };
  }

  private checkAndClearCache(key: STORE_KEYS): void {
    if (this.shouldClearCache) {
      this.storage.delete(key);
      this.shouldClearCache = false;
    }
  }
}
