import { hasTracingEnabled, getCurrentHub, getDynamicSamplingContextFromClient } from '@sentry/core';
import { generateSentryTraceHeader, dynamicSamplingContextToSentryBaggageHeader, isInstanceOf, BAGGAGE_HEADER_NAME } from '@sentry/utils';

/**
 * Create and track fetch request spans for usage in combination with `addInstrumentationHandler`.
 *
 * @returns Span if a span was created, otherwise void.
 */
function instrumentFetchRequest(handlerData, shouldCreateSpan, shouldAttachHeaders, spans, spanOrigin = 'auto.http.browser') {
  if (!hasTracingEnabled() || !handlerData.fetchData) {
    return undefined;
  }
  const shouldCreateSpanResult = shouldCreateSpan(handlerData.fetchData.url);
  if (handlerData.endTimestamp && shouldCreateSpanResult) {
    const spanId = handlerData.fetchData.__span;
    if (!spanId) return;
    const span = spans[spanId];
    if (span) {
      if (handlerData.response) {
        span.setHttpStatus(handlerData.response.status);
        const contentLength = handlerData.response && handlerData.response.headers && handlerData.response.headers.get('content-length');
        if (contentLength) {
          const contentLengthNum = parseInt(contentLength);
          if (contentLengthNum > 0) {
            span.setData('http.response_content_length', contentLengthNum);
          }
        }
      } else if (handlerData.error) {
        span.setStatus('internal_error');
      }
      span.finish();

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete spans[spanId];
    }
    return undefined;
  }
  const hub = getCurrentHub();
  const scope = hub.getScope();
  const client = hub.getClient();
  const parentSpan = scope.getSpan();
  const {
    method,
    url
  } = handlerData.fetchData;
  const span = shouldCreateSpanResult && parentSpan ? parentSpan.startChild({
    data: {
      url,
      type: 'fetch',
      'http.method': method
    },
    description: `${method} ${url}`,
    op: 'http.client',
    origin: spanOrigin
  }) : undefined;
  if (span) {
    handlerData.fetchData.__span = span.spanId;
    spans[span.spanId] = span;
  }
  if (shouldAttachHeaders(handlerData.fetchData.url) && client) {
    const request = handlerData.args[0];

    // In case the user hasn't set the second argument of a fetch call we default it to `{}`.
    handlerData.args[1] = handlerData.args[1] || {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options = handlerData.args[1];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    options.headers = addTracingHeadersToFetchRequest(request, client, scope, options, span);
  }
  return span;
}

/**
 * Adds sentry-trace and baggage headers to the various forms of fetch headers
 */
function addTracingHeadersToFetchRequest(request,
// unknown is actually type Request but we can't export DOM types from this package,
client, scope, options, requestSpan) {
  const span = requestSpan || scope.getSpan();
  const transaction = span && span.transaction;
  const {
    traceId,
    sampled,
    dsc
  } = scope.getPropagationContext();
  const sentryTraceHeader = span ? span.toTraceparent() : generateSentryTraceHeader(traceId, undefined, sampled);
  const dynamicSamplingContext = transaction ? transaction.getDynamicSamplingContext() : dsc ? dsc : getDynamicSamplingContextFromClient(traceId, client, scope);
  const sentryBaggageHeader = dynamicSamplingContextToSentryBaggageHeader(dynamicSamplingContext);
  const headers = typeof Request !== 'undefined' && isInstanceOf(request, Request) ? request.headers : options.headers;
  if (!headers) {
    return {
      'sentry-trace': sentryTraceHeader,
      baggage: sentryBaggageHeader
    };
  } else if (typeof Headers !== 'undefined' && isInstanceOf(headers, Headers)) {
    const newHeaders = new Headers(headers);
    newHeaders.append('sentry-trace', sentryTraceHeader);
    if (sentryBaggageHeader) {
      // If the same header is appended multiple times the browser will merge the values into a single request header.
      // Its therefore safe to simply push a "baggage" entry, even though there might already be another baggage header.
      newHeaders.append(BAGGAGE_HEADER_NAME, sentryBaggageHeader);
    }
    return newHeaders;
  } else if (Array.isArray(headers)) {
    const newHeaders = [...headers, ['sentry-trace', sentryTraceHeader]];
    if (sentryBaggageHeader) {
      // If there are multiple entries with the same key, the browser will merge the values into a single request header.
      // Its therefore safe to simply push a "baggage" entry, even though there might already be another baggage header.
      newHeaders.push([BAGGAGE_HEADER_NAME, sentryBaggageHeader]);
    }
    return newHeaders;
  } else {
    const existingBaggageHeader = 'baggage' in headers ? headers.baggage : undefined;
    const newBaggageHeaders = [];
    if (Array.isArray(existingBaggageHeader)) {
      newBaggageHeaders.push(...existingBaggageHeader);
    } else if (existingBaggageHeader) {
      newBaggageHeaders.push(existingBaggageHeader);
    }
    if (sentryBaggageHeader) {
      newBaggageHeaders.push(sentryBaggageHeader);
    }
    return {
      ...headers,
      'sentry-trace': sentryTraceHeader,
      baggage: newBaggageHeaders.length > 0 ? newBaggageHeaders.join(',') : undefined
    };
  }
}
export { addTracingHeadersToFetchRequest, instrumentFetchRequest };
