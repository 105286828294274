import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: number, decimalPlace = 0): string {
    const suffixes = ['k', 'k', 'm', 'b'];
    let valueClone = value
    let suffixIndex = 0;
    if (valueClone < 1000) {
      valueClone /= 1000;
    };
    while (valueClone >= 1000 && suffixIndex < suffixes.length - 1) {
      valueClone /= 1000;
      suffixIndex++;
    };
    return valueClone.toFixed(decimalPlace) + suffixes[suffixIndex];
  }
}
