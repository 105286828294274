import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_SCHEDULES } from '@app/utils/constants';
import { PricingUtils } from '@app/utils/pricing-util/pricing.util';
import { SubscriptionTier } from '@shared/meta-data';

@Pipe({
  name: 'subscriptionInterval',
})
export class SubscriptionIntervalPipe implements PipeTransform {
  transform(price: SubscriptionTier): unknown {
    if (!price) {
      return PricingUtils.getCapitalizedFrequency(PAYMENT_SCHEDULES.MONTHLY);
    }

    if (price.type !== 'recurring') {
      return 'NA';
    }

    const key = `${price.recurring.interval}-${price.recurring.interval_count}`;

    switch (key) {
      case 'year-1':
        return PricingUtils.getCapitalizedFrequency(PAYMENT_SCHEDULES.ANNUALLY);
      case 'month-6':
        return PricingUtils.getCapitalizedFrequency(
          PAYMENT_SCHEDULES['SEMI-ANNUALLY']
        );
      case 'month-3':
        return PricingUtils.getCapitalizedFrequency(
          PAYMENT_SCHEDULES.QUARTERLY
        );
      case 'month-1':
        return PricingUtils.getCapitalizedFrequency(PAYMENT_SCHEDULES.MONTHLY);
      case 'month-2':
        return PricingUtils.getCapitalizedFrequency(
          PAYMENT_SCHEDULES['BI-MONTHLY']
        );
      case 'week-2':
        return PricingUtils.getCapitalizedFrequency(
          PAYMENT_SCHEDULES['SEMI-MONTHLY']
        );

      default:
        return PricingUtils.getCapitalizedFrequency(PAYMENT_SCHEDULES.MONTHLY);
    }
  }
}
