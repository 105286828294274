import { createAction, props } from '@ngrx/store';
import { LinkedAccount, LinkedAccountEvent } from '@shared/meta-data';

export const ResetStore = createAction('[Linked Account] Reset Store');

export const IsLoading = createAction(
  '[Linked Account] Is Loading',
  props<{ isLoading: boolean }>()
);

export const GetLinkedAccounts = createAction(
  '[Linked Account] Get Linked Accounts ',
);

export const SaveLinkedAccounts = createAction(
  '[Linked Account] Save Linked Accounts ',
  props<{ payload: any }>()
);

export const GetLinkedAccountsEvents = createAction(
  '[Linked Account] Get Linked Accounts Events',
);

export const SaveLinkedAccountsEvents = createAction(
  '[Linked Account] Save Linked Accounts Events',
  props<{ payload: any }>()
);

export const SetNewEventsWhereProcessed = createAction(
  '[Linked Account] Set New Events Where Processed',
  props<{ newEventsWhereProcessed: boolean }>()
);

export const SetSynchronizationComplete = createAction(
  '[Linked Account] Set Synchronization Complete',
  props<{ isSyncComplete: boolean }>()
);

export const SaveLinkedAccountsEventMap = createAction(
  '[Linked Account] Save Linked Accounts Event Map',
  props<{ map: Map<LinkedAccount, LinkedAccountEvent> }>()
);

export const CombineLinkedAccountsAndEvents = createAction(
  '[Linked Account] Combine Linked Accounts and Events',
  props<{ accounts: LinkedAccount[], events: LinkedAccountEvent[] }>()
);

export const SaveTotalProcessedTransactions = createAction(
  '[Linked Account] Save Total Processed Transactions',
  props<{  totalProcessedTransactions: number }>()
);
