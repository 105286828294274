import { Pipe, PipeTransform } from '@angular/core';
import { STATES, StateCode } from '@shared/components/address-list/address-list-form.interface';

@Pipe({
  name: 'stateCodeToName'
})
export class StateCodeToNamePipe implements PipeTransform {

  transform(stateCode: StateCode): string {
    return STATES[stateCode];
  }

}
