export interface LinkedAccount {
  _metadata: string;
  _provider: string;
  _pull_count: string;
  _pull_from: string;
  _pull_to: string;
  _scopes: string;
  _store: string;
  _token: string;
  _type: string;
  _user_id: string;
  _validFrom: string;
  _validTo: string;
  _token_id: number;
}

export interface LinkedAccountEvent {
  business_profile_id: number;
  occured_at: string;
  provider: string;
  status: string;
  store: string;
  token_id: number;
  transaction_events_id: number;
  type: string;
}

export enum LinkedAccountEventStatus {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  INSTALLED = 'INSTALLED',
  DATA_PUSHED = 'DATA_PUSHED'
}

