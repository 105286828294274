import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewCardForm } from '../payment.interface';
import { PlanAndBillingService, StripeCard } from '@services/plan-and-billing/plan-and-billing.service';
import { StripeCardToken } from '@shared/meta-data/stripe-card-token.meta';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-new-card-form',
  templateUrl: './new-card-form.component.html'
})
export class NewCardFormComponent implements OnInit {
  isLoading = false
  @Output() cardTokenizeStart = new EventEmitter<any>()
  @Output() cardTokenizeEnd = new EventEmitter<StripeCardToken>()
  @Output() cardTokenizeError = new EventEmitter<any>()
  form: FormGroup<NewCardForm>

  constructor(private readonly fb: FormBuilder, private readonly planService: PlanAndBillingService) {

  }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.form = this.fb.group({
      cardNumber: ['', Validators.required],
      cardCVV: ['', Validators.required],
      cardExpMonth: ['', Validators.required],
      cardExpYear: ['', Validators.required],
    })
  }

  submitForm() {
    this.cardTokenizeStart.emit('starting to tokenize card')
    this.isLoading = true

    const { cardCVV, cardExpMonth, cardExpYear, cardNumber } = this.form.value
    const cardDetails: StripeCard = { cvc: cardCVV, exp_month: cardExpMonth, exp_year: cardExpYear, number: cardNumber }
    this.planService.tokenizeCard(cardDetails).pipe(finalize(() => this.isLoading = false)).subscribe({
      next: (value) => {
        this.cardTokenizeEnd.emit(value)
      },
      error: (err) => {
        this.isLoading = false
        this.cardTokenizeError.emit(err)
      },
    })
  }
}
