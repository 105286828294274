import { Pipe, PipeTransform } from '@angular/core';
import { UNIT_RATES } from '@app/utils/constants';

@Pipe({
  name: 'centConversion'
})
export class CentConversionPipe implements PipeTransform {

  transform(amountInCents: number): string {
    if (isNaN(amountInCents) || typeof amountInCents !== 'number') {
      return (0).toFixed(2);
    }

    return (amountInCents / UNIT_RATES.CENTS).toFixed(2);
  }

}
