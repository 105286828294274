import { SubscriptionTier } from "@shared/meta-data";
import { PAYMENT_SCHEDULES } from "../constants";

export class PricingUtils {
    static getValue(prices: SubscriptionTier[], interval: string, intervalCount: number): SubscriptionTier | null {
        const price = prices.find(price => price.recurring.interval === interval && price.recurring.interval_count === intervalCount);
        return price || null;
    }

    static getDefaultValue(prices: SubscriptionTier[]): SubscriptionTier | null {
        return prices.length ? prices[0]: null;
    }

    static getCapitalizedFrequency(frequency: string): string {
        if (!frequency) return 'Monthly';

        const frequencyString = frequency.replace('-', ' ').toLowerCase();
        return frequencyString.charAt(0).toUpperCase() + frequencyString.slice(1);
    }

    static getPriceForFrequency(prices: SubscriptionTier[], frequency: string): SubscriptionTier | null {
        if(!prices || prices.length === 0) return null;

        const lowercaseFrequency = frequency?.toLowerCase();

        switch (lowercaseFrequency) {
            case PAYMENT_SCHEDULES.ANNUALLY.toLowerCase():
            case PAYMENT_SCHEDULES['FISCAL-ANNUALLY'].toLowerCase():
                return this.getValue(prices, 'year', 1);

            case PAYMENT_SCHEDULES['SEMI-ANNUALLY'].toLowerCase():
                return this.getValue(prices, 'month', 6);

            case PAYMENT_SCHEDULES.QUARTERLY.toLowerCase():
            case PAYMENT_SCHEDULES['QUARTERLY-PREPAY'].toLowerCase():
                return this.getValue(prices, 'month', 3);

            case PAYMENT_SCHEDULES.MONTHLY.toLowerCase():
                return this.getValue(prices, 'month', 1);

            case PAYMENT_SCHEDULES['BI-MONTHLY'].toLowerCase():
                return this.getValue(prices, 'month', 2);

            case PAYMENT_SCHEDULES['SEMI-MONTHLY'].toLowerCase():
                return this.getValue(prices, 'week', 2);

            default:
                return this.getDefaultValue(prices);
        }
    }
}