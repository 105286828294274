import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { DownloadService } from '@shared/services/download/download.service';
import { finalize, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './download.component.html',
})
export class DownloadComponent implements OnInit, OnDestroy {
  downloading: boolean = true;
  error: string = '';

  private readonly downloadService: DownloadService = inject(DownloadService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      this.activatedRoute.params.subscribe((params) => {
        const config = params['config'];
  
        if (config) {
          this.downloadFile(encodeURIComponent(config));
        }
      })
    )
  }

  downloadFile(config: string): void {
    const downloadUrl = `${environment.API_URL}/download/${config}`;

    this.subscription.add(
      this.downloadService.downloadBinaryFile(downloadUrl).pipe(
        finalize(() => {
          this.downloading = false;
        })  
      ).subscribe({
        next: (response: HttpResponse<string> | null) => {
          if (response && response.body) {
            this.error = '';
            
            const contentDisposition = response.headers.get('Content-Disposition');
            const contentType = response.headers.get('Content-Type');
            let fileName;
  
            if (contentDisposition) {
              const contentDispositionParts = contentDisposition.split(';');
              const fileNamePart = contentDispositionParts.find(part => part.trim().startsWith('filename='));
  
              if (fileNamePart) {
                fileName = fileNamePart.split('=')[1].trim().replace(/['"]/g, '');
              }
            }

            const byteCharacters  = atob(response.body)
            const byteNumbers = new Array(byteCharacters.length);
            
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });
  
            this.createDownloadLink(blob, fileName);
          }
  
        },
        error: (error: HttpErrorResponse) => {
          this.error = error.statusText;
        },
      })
    )
  }

  createDownloadLink(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(a.href);
  }

  retryDownload(): void {
    this.downloading = true;
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
