import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import * as fromApp from '@app/@store/app/app.reducer';
import * as LinkedAccountActions from '@app/@store/linked-account/linked-account.actions';
import * as LinkedAccountSelectors from '@app/@store/linked-account/linked-account.selectors';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkedAccount, LinkedAccountEvent, LinkedAccountEventStatus } from '@shared/meta-data';
import { Router } from '@angular/router';
@Component({
  selector: 'app-linked-provider-processing',
  templateUrl: './linked-provider-processing.component.html',
  styleUrl: './linked-provider-processing.component.scss',
})
export class LinkedProviderProcessingComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  articles: {title: string, description: string, img: string, link: string}[] = [];
  isSyncComplete: boolean;
  linkedAccountsMap$: Observable<Map<LinkedAccount, LinkedAccountEvent>>;
  linkedAccountEventStatus = LinkedAccountEventStatus;

  private subscription: Subscription = new Subscription();
  private readonly store: Store = inject(Store<fromApp.AppState>);
  private readonly activeModal: NgbActiveModal = inject(NgbActiveModal);
  private readonly router: Router = inject(Router);

  @Input() isNewUser: boolean = false;

  constructor() {
    this.listenToBooleanObservables();
  }

  ngOnInit(): void {
    this.setArticles();

    this.linkedAccountsMap$ = this.store.select(LinkedAccountSelectors.getLinkedAccountsWithEventMap);  }

  listenToBooleanObservables() {
    this.isLoading$ = this.store.select(LinkedAccountSelectors.getIsLoading);

    this.subscription.add(
      this.store.pipe(
        select(LinkedAccountSelectors.getIsSyncComplete)
      ).subscribe((isSyncComplete) => {
        this.isSyncComplete = isSyncComplete;
      })
    )
  }

  getEntries(map: Map<LinkedAccount, LinkedAccountEvent>): [LinkedAccount, LinkedAccountEvent][] {
    return Array.from(map.entries());
  }

  setArticles() {
    this.articles = [
      {
        title:
          'How Sales Tax Work in the US - State by State in 2024 (Definitive Guide)',
        description:
          'One of the biggest hurdles for online businesses as the ecommerce industry experiences a great boom is the numerous complex and frequently changing sales tax compliance regulations and laws across different US states. Failing to comply can lead to hefty penalties, audits, and headaches down the road.',
        img: 'https://via.placeholder.com/150',
        link: 'https://atomictax.com/blog/us-state-by-state-sales-tax/',
      },
      {
        title: 'How Long Does it Take to Get a New York Sales Permit',
        description:
          'If you’re planning to sell taxable goods or services in New York, obtaining a New York sales permit is an essential step. This permit not only ensures that you comply with state tax laws but also allows you to legally collect sales tax from your customers.',
        img: 'https://via.placeholder.com/150',
        link: 'https://atomictax.com/blog/new-york-sales-tax-permit-application-processing-time/',
      },
      {
        title: 'How Much Does it Cost to Get a Seller Permit in New York',
        description: `In New York, applying for a sales tax permit is free of charge. This is a significant advantage for new businesses as it eliminates the initial <b>sales tax permit application fee</b> that might be found in other states​​. However, there are other cost components to consider when we talk about New York seller permit cost`,
        img: 'https://via.placeholder.com/150',
        link: 'https://atomictax.com/blog/new-york-sales-tax-registration-cost-and-application-fee/',
      },
    ];
  }

  close() {
    if(this.isSyncComplete) {
      this.store.dispatch(LinkedAccountActions.SetNewEventsWhereProcessed({newEventsWhereProcessed: false}))
    }

    this.activeModal.close();

    if(this.isNewUser) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
