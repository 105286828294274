<section class="section-border border-primary">
	<div class="container d-flex flex-column">
		<div class="row align-items-center justify-content-center gx-0 min-vh-100">
			<div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
				<h1 class="display-3 fw-bold text-center">
					Redirecting you shortly.
				</h1>
				<p class="mb-5 text-center text-muted">
					You will be redirected to {{ hostname }} @if(countingDown) { in {{ counter }} second(s) }
				</p>
				<div class="text-center">
					<button (click)="proceed()" class="btn btn-primary">
						Proceed Now
						<i class="mdi mdi-open-in-new"></i>
					</button>
					<button (click)="redirectSubscription.unsubscribe()" [disabled]="!redirectSubscription" class="btn btn-muted">
						Cancel
					</button>
				</div>
			</div>
		</div>
	</div>
</section>
