import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { RouteInfo } from './sidebar.metadata';
import { ROUTES as MAIN_ROUTES } from './menu-items';
import { ROUTES as COOPERATE_TAX_ROUTES } from './cooperate-tax-menu-items';


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  items = new BehaviorSubject<RouteInfo[]>([]);

  constructor(
    private readonly router: Router
  ) {
    this.updateSidebar();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: () => this.updateSidebar()
      });

  }

  updateSidebar() {
    if (this.router.url.includes('/cooperate-tax/')) {
      this.items.next(COOPERATE_TAX_ROUTES);
    } else {
      this.items.next(MAIN_ROUTES);
    }
  }
}
