import { Component, ElementRef, ViewChild } from '@angular/core';
import { UtilityService } from '@app/utils/utility.service';

@Component({
  selector: 'app-welcome-board',
  templateUrl: './welcome-board.component.html'
})
export class WelcomeBoardComponent {
  public document = document;

  @ViewChild('fileImportInput', { static: false }) fileImportInput: ElementRef<HTMLInputElement>;

  constructor(
    private utility: UtilityService
  ) {
  }

  async fileChangeListener($event: any): Promise<void> {
    this.utility.validateTransactionsCSVFile($event.srcElement.files[0]).subscribe();
  }

}
