import { Component, OnDestroy } from '@angular/core';
import { Data } from '@angular/router';
import { UtilityService } from '@app/utils/utility.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnDestroy {
  pageInfo: Data = Object.create(null);

  subscription: Subscription = new Subscription();

  constructor(
   private utilityService: UtilityService
  ) {

    this.subscription.add(
      this.utilityService.pageInformation.subscribe((data)=>{
      this.pageInfo = data
    }))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
