import { interval, Observable, Subscription } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BusinessProfileService } from '@services/business-profile/business-profile.service';
import { AppConfig } from '@app/app.config';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit {
  redirectURL: URL;
  counter = 5;
  countingDown = false;
  hostname = '';
  redirectSubscription: Subscription;

  constructor(
    private readonly businessProfileService: BusinessProfileService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap
      .subscribe({
        next: queryParamMap => {
          if (queryParamMap.has('redirect_to')) {
            this.handleRedirect(queryParamMap.get('redirect_to'));
          } else {
            this.router.navigate(['/dashboard'], { queryParams: {} });
          }
        }
      });
  }

  handleRedirect(redirect_to: string): void {
    this.redirectURL = new URL(redirect_to);
    this.hostname = this.redirectURL.hostname;

    if (this.hostname !== new URL(AppConfig.API_URL).hostname) {
      return;
    }

    this.attachForShopify();
    this.redirectSubscription = this.redirect().subscribe();
  }

  redirect(): Observable<number> {
    return interval(1000)
      .pipe(
        takeWhile(() => this.counter > 0),
        tap(() => {
          if (this.counter === 1) {
            this.proceed();
          }
          this.counter--;
        }));
  }

  proceed(): void {
    window.open(this.redirectURL.toString(), '_self');
  }

  attachForShopify(): void {
    this.countingDown = true;

    if (this.redirectURL.searchParams.has('user_id')) {
      this.redirectURL.searchParams.set('x_user_id', this.redirectURL.searchParams.get('user_id'));
    }

    if (this.redirectURL.searchParams.has('business_profile_id')) {
      this.redirectURL.searchParams.set('x_business_profile_id', this.redirectURL.searchParams.get('business_profile_id'));
    }

    this.redirectURL.searchParams.set('user_id', this.businessProfileService.businessProfile.user_id);
    this.redirectURL.searchParams.set('business_profile_id', this.businessProfileService.businessProfile.business_profile_id.toString());
  }
}
