import moment, { Moment } from 'moment/moment';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CURRENCY_UNIT, DEFAULT_DATE_FORMAT, UNIT_RATES } from '@app/utils/constants';
import { environment } from 'src/environments/environment';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { asyncScheduler, scheduled } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';

export default class Helpers {

  /**
   *
   * Logs items based on environment
   * @static
   * @param {*} item
   * @param {string} [context='debug']
   * @memberof Helpers
   */
  static log(item, context = 'debug',) {
    if (!environment.production) { console.log(context, ' :: ', item) }
  }

  /**
   * Waits for a given number of milliseconds.
   *
   * @param ms
   *
   * @returns promise to wait
   */
  static async wait(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  static getTodaysDate(): string {
    return moment().format(DEFAULT_DATE_FORMAT);
  }

  static matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }
  static getMonthsInRange(startDate: Moment, endDate: Moment) {

    const dates = [];

    while (startDate.isSameOrBefore(endDate)) {
      dates.push(moment(startDate));
      startDate.add(1, 'month')
    }

    return dates;
  }

  static isSameMonthAndYear(date1: DateArg, date2: DateArg) {
    return this.isSameMonth(date1, date2) && this.isSameYear(date1, date2)
  }

  static isSameMonth(date1: DateArg, date2: DateArg) {
    return moment(date1).month() === moment(date2).month()
  }

  static isSameYear(date1: DateArg, date2: DateArg) {
    return moment(date1).year() === moment(date2).year()
  }

  static snakeCaseToString(letter: string) {
    return letter.replaceAll('_', ' ')
  }

  static currencyConverter(value: string | number, to: CURRENCY_UNIT): number {
    value = Number(value)
    if (!Number.isInteger(value)) return 0

    let result: number
    switch (to) {
      case CURRENCY_UNIT.CENT:
        result = value * UNIT_RATES.CENTS
        break;

      case CURRENCY_UNIT.DOLLAR:
        result = value / UNIT_RATES.CENTS
        break;

      default:
        result = 0
        break;
    }

    return result
  }

  static ObjectIsEmpty(object: Record<string, any>): boolean {
    return Object.keys(object).length <= 0
  }

  static sortArrayOfObject<T>(prop: string, order: 'asc' | 'desc', array: T[], transformFunction = (arg) => arg) {
    return array.sort((a, b) => {
      if (order === 'asc') {
        return transformFunction(a[prop]) - transformFunction(b[prop])
      }
      if (order === 'desc') {
        return transformFunction(b[prop]) - transformFunction(a[prop])
      }
    })
  }

  /**
  * This function takes care of two things
  * One, it combines dismiss and close observable into one stream so we can listen to the same stream
  * if the modal was hidden either with the close or dismiss method
  *
  * Two it also coverts the retun type to a boolean if it was dismissed (res===0). When the modal is dismisse it is always dissmised
  * with a 0. But when closed it is closed with and value you supply. Since this method is going to be reusable by other modals,
  * and we don't know if they would close and return a value, we are adding this second case scenario.
  *  */
  static afterModalClose(ref: NgbModalRef) {
    return scheduled([ref.closed, ref.dismissed], asyncScheduler).pipe(mergeAll(), map((res) => typeof res === 'boolean' || res === 0 ? Boolean(res) : res))
  }
}

type DateArg = Moment | string | Date
