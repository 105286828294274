import { AddressListViewModel, COUNTRIES, STATES } from './address-list-form.interface';

export const MOCK_ADDRESS_LIST_FORM_VIEW_MODEL: AddressListViewModel = {
    addressGroups: [
        {
            group: 'warehouse',
            addresses: [
                {
                    city: 'Los Angeles',
                    country: COUNTRIES.US,
                    state: STATES.CA,
                    street: 'sample street x',
                    zipcode: '97528',
                },
                {
                    city: 'NewPark Hills',
                    country: COUNTRIES.US,
                    state: STATES.AR,
                    street: 'sample street x',
                    zipcode: '80293',
                },
                {
                    city: 'Detroit',
                    country: COUNTRIES.US,
                    state: STATES.MI,
                    street: 'sample street x',
                    zipcode: '123456',
                },
                {
                    city: 'Foo',
                    country: COUNTRIES.US,
                    state: STATES.OH,
                    street: 'sample street x',
                    zipcode: '98830',
                },
                {
                    city: 'Clay County',
                    country: COUNTRIES.US,
                    state: STATES.CO,
                    street: 'sample street x',
                    zipcode: '32003',
                },
            ],
        },
        {
            group: 'office',
            addresses: [
                {
                    city: 'Los Angeles 2',
                    country: COUNTRIES.US,
                    state: STATES.CA,
                    street: 'sample street x',
                    zipcode: '97528',
                },
                {
                    city: 'NewPark Hills 2',
                    country: COUNTRIES.US,
                    state: STATES.AR,
                    street: 'sample street x 2',
                    zipcode: '80293',
                },
                {
                    city: 'Detroit',
                    country: COUNTRIES.US,
                    state: STATES.MI,
                    street: 'sample street x 3',
                    zipcode: '123456',
                },
                {
                    city: 'Foo',
                    country: COUNTRIES.US,
                    state: STATES.OH,
                    street: 'sample street x 4',
                    zipcode: '98830',
                },
                {
                    city: 'Clay County',
                    country: COUNTRIES.US,
                    state: STATES.CO,
                    street: 'sample street x 5',
                    zipcode: '32003',
                },
            ],
        },
    ],
};
