<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{config.title}}</h4>
    <button type="button" (click)="cancel()" class="btn-close" aria-describedby="modal-title"></button>
</div>
<div class="modal-body">
    <p [innerHTML]="safeHtml"></p>
</div>
<div class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-outline-secondary">{{config.negativeActionText}}</button>
    <button type="button" (click)="proceed()" class="btn btn-primary">{{config.positiveActionText}}</button>
</div>