import { StateCode } from '@shared/components/address-list/address-list-form.interface';
import { BillingProvider } from './subscription.meta';

export interface StateRegistrationData {
  [key: string]: string;
}

export interface AutoRegisterCreatePayload {
  state: StateCode;
  registration_json: string;
}

export interface AutoRegisterUpdatePayload extends AutoRegisterCreatePayload, Pick<AutoRegister, 'business_profile_id' | 'merchant_state_registration_id' | 'state' | 'status'> {

}

export interface AutoRegister {
  business_profile_id: number;
  created_at: string;
  merchant_state_registration_id: number;
  registration_data: StateRegistrationData;
  state: StateCode;
  status: AUTO_REGISTER_STATUSES;
  updated_at: string;
}

export enum AUTO_REGISTER_STATUSES {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  REGISTERED = 'REGISTERED',
  PROCESSING = 'PROCESSING',
}

export interface AutoRegisterPayment {
  price: string;
  merchant_state_registration_id: number;
  token?: string;
  payment_method_id?: string;
  provider: BillingProvider;
  use_credits?: boolean;
}

export interface AutoRegisterPipeIds {
  Register: {
    [key in StateCode]: string;
  };
}

export interface AutoRegisterPipeConfig {
  pipes: Array<AutoRegisterStateConfig>;
}

export interface AutoRegisterStateConfig {
  uuid: string;
  description: string;
  emailAddress: string;
  id: string;
  name: string;
  organizationId: string;
  start_form_fields: Array<AutoRegisterStateFormField>;
}

export interface AutoRegisterStateFormField {
  uuid: string;
  description: string;
  id: string;
  required: boolean;
  label: string;
  type: string;
  index: number;
}
