const TRANSACTIONS_FILE_UPLOAD_STATUSES = {
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED'
};
const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY';
const SALES_TAX_FILTER_OPTIONS = {
  COLLECTED: 'COLLECTED',
  NOT_COLLECTED: 'NOT_COLLECTED'
};
const UNITS = {
  CENTS: 'CENTS',
  DOLLARS: 'DOLLARS'
};
const UNIT_RATES = {
  // We store amounts in the lowest value of the currency.
  // The UNIT_RATES is the multiplier by which the amount will be multiplied by before storage
  // see https://www.martinfowler.com/eaaCatalog/money.html for more information
  // This is mostly to aid calculations and prevent precision losses due to floats
  // If the DataSource is in CENTS, we multiply by 1
  CENTS: 1,
  // If the DataSource is in DOLLARS, we multiply by 100
  DOLLARS: 100
};
const DATA_SOURCES = {
  CSV_UPLOAD_DATA_SOURCE: 'CSV_UPLOAD_DATA_SOURCE',
  AMAZON_DATA_SOURCE: 'AMAZON_DATA_SOURCE',
  SHOPIFY_DATA_SOURCE: 'SHOPIFY_DATA_SOURCE',
  STRIPE_DATA_SOURCE: 'STRIPE_DATA_SOURCE',
  API_DATA_SOURCE: 'API_DATA_SOURCE'
};
const EMAIL_TEMPLATES = {
  WELCOME_TO_ATOMICKIT: 'WELCOME_TO_ATOMICKIT_SALES_TAX_AUTOMATION',
  BUSINESS_PROFILE_CREATE: 'BUSINESS_PROFILE_CREATED',
  BUSINESS_PROFILE_UPDATE: 'BUSINESS_PROFILE_UPDATED',
  NEXUS_STATE_ADDED: 'NEW_NEXUS_STATE_ADDED',
  NEXUS_STATE_UPDATED: 'NEW_NEXUS_STATE_UPDATED',
  MERCHANT_UPLOAD_FILES_STARTED: 'YOUR_CSV_FILE_HAS_BEEN_RECEIVED',
  MERCHANT_UPLOAD_FILES_COMPLETED: 'YOUR_CSV_PROCESSING_HAS_BEEN_COMPLETED'
};
const FILING_STATUS = {
  OPEN: 'O',
  CLOSED: 'C'
};
const MARKETPLACE_FACILITATORS = {
  AMAZON: 'amazon',
  EBAY: 'ebay',
  ETSY: 'etsy',
  WALMART: 'walmart'
};
const PROVIDERS = {
  ...MARKETPLACE_FACILITATORS,
  SHOPIFY: 'shopify',
  STRIPE: 'stripe'
};
const EXEMPTIONS_TYPES = {
  WHOLESALE: 'Wholesale',
  GOVERNMENT: 'Government',
  OTHER: 'Other',
  MARKETPLACE: 'Marketplace',
  NON_EXEMPT: 'Non_Exempt'
};
const SUBSCRIPTION_TRIAL_PERIOD = 30;
const STRIPE_SUBSCRIPTION_STATUS_MAPPING = {
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'EXPIRED',
  TRIALING: 'TRIAL',
  ACTIVE: 'ACTIVE',
  PAST_DUE: 'PAST_DUE',
  CANCELED: 'CANCELED',
  UNPAID: 'UNPAID'
};
const SHOPIFY_RECURRING_APPLICATION_CHARGE_STATUS_MAPPING = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  FROZEN: 'FROZEN',
  CANCELLED: 'CANCELLED'
};
const NOTIFICATION_MESSAGES = {
  POST_ONBOARDING: 'Welcome to AtomicTax, do not forget to connect your sales channels'
};
const NOTIFICATION_TYPES = {
  REPORT: 'REPORT',
  PAYMENT: 'PAYMENT',
  ONBOARDING: 'ONBOARDING',
  TRANSACTIONS: 'TRANSACTIONS'
};
const NOTIFICATION_PRIORITY_LEVELS = {
  HIGH: '1',
  MEDIUM: '2',
  LOW: '3'
};
const API_TOKEN_TYPES = {
  SANDBOX: 'SANDBOX',
  PRODUCTION: 'PRODUCTION'
};
const REDIS_CACHE_EXPIRY = 86400;
const RATE_LIMIT_SETTINGS = {
  max: 10000,
  timeFrame: 60000
};
const BUSINESS_USER_ROLES = {
  ADMIN: 'ADMIN',
  ACCOUNTANT: 'ACCOUNTANT',
  SUPPORT: 'SUPPORT'
};
const AUTO_REGISTER_STATUSES = {
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  REGISTERED: 'REGISTERED',
  PROCESSING: 'PROCESSING'
};
const validNamePattern = '^[A-Za-z.-]*$';
const phoneNumberPattern = '^[\\d ()+-]+$';
const accountStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUBSCRIBED: 'SUBSCRIBED',
  IN_TRIAL: 'IN_TRIAL',
  CANCELLED: 'CANCELLED',
  TRIAL_EXPIRED: 'TRIAL_EXPIRED',
  PENDING_CANCELLATION: 'PENDING_CANCELLATION'
};
const ACCOUNT_STATUS = accountStatus;
const averageOrders = {
  200: 'Between 0 and 200',
  500: 'Between 201 and 500',
  1000: 'Between 501 and 1,000',
  5000: 'Between 1,001 and 5,000',
  10000: 'Between 5,001 and 10,000+'
};
const TRANSACTIONS_CSV_HEADERS = ['provider', 'transaction_id', 'transaction_type', 'transaction_reference_id', 'transaction_date', 'to_street', 'to_city', 'to_state', 'to_zip', 'to_country', 'from_street', 'from_city', 'from_state', 'from_zip', 'from_country', 'total_shipping', 'total_handling', 'total_sales_tax', 'item_product_identifier', 'item_description', 'item_quantity', 'item_unit_price', 'item_shipping', 'item_handling', 'item_discount', 'item_sales_tax', 'item_product_tax_code', 'exemption_type', 'amount'];
const PICKER_DATE_FORMAT = DEFAULT_DATE_FORMAT.replaceAll('D', 'd');
const CURRENCY_UNIT = {
  DOLLAR: 'dollar',
  CENT: 'cent'
};
const FILE_SIZE = {
  BYTE: 1,
  KILOBYTE: 1024,
  MEGABYTE: 1048576,
  GIGABYTE: 1073741824,
  TERABYTE: 1099511627776
};
const FILING_STATUSES = {
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  REGISTERED: 'REGISTERED',
  PROCESSING: 'PROCESSING'
};
const TRANSACTION_TYPE = {
  CSV: {
    ORDER: 'ORDER',
    REFUND: 'REFUND'
  }
};
module.exports = {
  TRANSACTIONS_FILE_UPLOAD_STATUSES,
  DEFAULT_DATE_FORMAT,
  SALES_TAX_FILTER_OPTIONS,
  UNITS,
  UNIT_RATES,
  DATA_SOURCES,
  EMAIL_TEMPLATES,
  FILING_STATUS,
  MARKETPLACE_FACILITATORS,
  PROVIDERS,
  EXEMPTIONS_TYPES,
  SUBSCRIPTION_TRIAL_PERIOD,
  STRIPE_SUBSCRIPTION_STATUS_MAPPING,
  SHOPIFY_RECURRING_APPLICATION_CHARGE_STATUS_MAPPING,
  NOTIFICATION_MESSAGES,
  NOTIFICATION_TYPES,
  NOTIFICATION_PRIORITY_LEVELS,
  API_TOKEN_TYPES,
  REDIS_CACHE_EXPIRY,
  RATE_LIMIT_SETTINGS,
  BUSINESS_USER_ROLES,
  AUTO_REGISTER_STATUSES,
  validNamePattern,
  phoneNumberPattern,
  accountStatus,
  ACCOUNT_STATUS,
  averageOrders,
  TRANSACTIONS_CSV_HEADERS,
  PICKER_DATE_FORMAT,
  CURRENCY_UNIT,
  FILE_SIZE,
  FILING_STATUSES,
  TRANSACTION_TYPE
};