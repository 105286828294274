import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  private delimiter: string = '/'; 

  setDelimiter(delimiter: string) {
    this.delimiter = delimiter;
  }

  parse(value: string): NgbDateStruct | null {
    if (value != null) {
      const dateParts = value.trim().split(/\/|-/);

      if (dateParts.length === 3) {
        return { month: Number(dateParts[0]), day: Number(dateParts[1]), year: Number(dateParts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (!date || !date.day || !date.month || !date.year) {
      return ''
    }

    return `${this.pad(date.month)}${this.delimiter}${this.pad(date.day)}${this.delimiter}${date.year}`
  }

  pad(number: number) {
    return number < 10 ? `0${number}` : number;
  }
}