import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AUTO_FILING_STATUSES, AUTO_REGISTER_STATUSES, AutoFiling, AutoRegister } from '@shared/meta-data';

@Component({
  selector: 'app-state-card',
  templateUrl: './state-card.component.html',
  styleUrl: './state-card.component.scss'
})
export class StateCardComponent {
  autoFilingStatuses = AUTO_FILING_STATUSES
  autoRegisterStatuses = AUTO_REGISTER_STATUSES

  @Input() type: 'danger' | 'warning' | 'success' | 'secondary' = 'secondary';
  @Input({ required: true }) useCase: 'filing' | 'register';
  @Input() amount: number;
  @Input() canSuspendAutofiling: boolean;

  @Output() cancelAutofiling = new EventEmitter<any>();
  @Output() makePayment: EventEmitter<AutoFiling | AutoRegister> = new EventEmitter<AutoFiling | AutoRegister>();

  private _state: any;

  get state(): any {
    return this._state;
  }

  @Input({ required: true })
  set state(value:any) {
    this._state = value;
  }
}
