export interface GenericItem {
  id: string | number;
  name: string;
}

export interface IResponse<T> {
  statusCode: number;
  status: string;
  message: string;
  error?: boolean;
  content: T;
}


export interface Paginated<T> {
  current_page: number;
  data: T[];
  from: number;
  last_page: number;
  offset: number;
  per_page: number;
  to: number;
  total: number;
}

export class Page<T> implements Paginated<T> {
  constructor(options: Partial<Paginated<T>> = {}) {
    for (const [key, value] of Object.entries(options)) {
      this[key] = value
    }
  }
  current_page = 0;
  data = [];
  from = 0;
  to = 0;
  total = 0;
  offset = 0;
  per_page = 0;
  last_page = 0;
}

export interface SortEvent {
  sorts: Array<{ dir: string, props: string }>;
  column: any;
  prevValue: 'asc' | 'desc';
  newValue: 'asc' | 'desc';
}
