<input  data-test-id="input" class="form-control" type="text" (input)="validateInput($event.target.value)" [value]="value" placeholder="mm/dd/yyyy - mm/dd/yyyy" ngbDatepicker #d="ngbDatepicker"
  (dateSelect)="onDateSelection($event, d)" [dayTemplate]="t" [displayMonths]="2" [autoClose]="'outside'" (click)="d.toggle()" />

<ng-template #t let-date let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
