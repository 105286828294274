const PERIODS = {
  ANNUALLY: 'ANNUALLY',
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  'SEMI-MONTHLY': 'SEMI-MONTHLY',
  'FISCAL-ANNUALLY': 'FISCAL-ANNUALLY',
  'SEMI-ANNUALLY': 'SEMI-ANNUALLY',
  'QUARTERLY-PREPAY': 'QUARTERLY-PREPAY',
  'BI-MONTHLY': 'BI-MONTHLY'
};
module.exports = {
  PERIODS
};