import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, startWith, Subscription, switchMap, timer } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as formApp from '@app/@store/app/app.reducer';
import * as LinkedAccountSelectors from '@app/@store/linked-account/linked-account.selectors';
import * as LinkedAccountActions from '@app/@store/linked-account/linked-account.actions';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkedProviderProcessingComponent } from '@shared/components/linked-provider-processing/linked-provider-processing.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-processing-provider-banner',
  templateUrl: './processing-provider-banner.component.html',
})
export class ProcessingProviderBannerComponent implements OnInit, OnDestroy {
  canShowProviderProcessing: boolean;

  newEventsWhereProcessed$: Observable<boolean>;
  isSyncComplete$: Observable<boolean>;
  
  private subscription: Subscription = new Subscription();

  private readonly modalService: NgbModal = inject(NgbModal);
  private readonly store: Store = inject(Store<formApp.AppState>);

  ngOnInit(): void {
    this.listenToObservables();

    this.canShowProviderProcessing =
      environment.FEATURE_FLAG.linked_account_processing;

    this.getLinkedAccountsAndEvents()      
  }

  listenToObservables() {
    this.newEventsWhereProcessed$ = this.store.select(LinkedAccountSelectors.getNewEventsProcessed);
    this.isSyncComplete$ = this.store.select(LinkedAccountSelectors.getIsSyncComplete);
  }

  getLinkedAccountsAndEvents() {
    this.subscription.add(
      this.store.pipe(
        select(LinkedAccountSelectors.getNewEventsProcessed),
        startWith(true),
        switchMap(shouldRun => {
          if(shouldRun) {
            this.store.dispatch(LinkedAccountActions.GetLinkedAccounts());
            this.store.dispatch(LinkedAccountActions.GetLinkedAccountsEvents()); 
          }
          return timer(0, 15000).pipe( filter(() => shouldRun))
        })
      ).subscribe(() => {
        this.store.dispatch(LinkedAccountActions.GetLinkedAccounts());
        this.store.dispatch(LinkedAccountActions.GetLinkedAccountsEvents()); 
      })
    );
  }

  openFullScreenDialog(): void {
    this.modalService.open(LinkedProviderProcessingComponent, {
      fullscreen: true,
    });
  }

  closeBanner(): void {
    this.store.dispatch(LinkedAccountActions.SetNewEventsWhereProcessed({newEventsWhereProcessed: false}));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
