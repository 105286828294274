import { Page, Paginated } from '@shared/meta-data/generic.meta';

export interface SalesTransaction {
  user_id: string;
  transaction_date: Date | string;
  provider: string;
  exemption_type: string;
  from_country: string;
  from_zip: string;
  from_city: string;
  from_street: string;
  from_state: string;
  to_country: string;
  to_zip: string;
  to_city: string;
  to_street: string;
  to_state: string;
  amount: number;
  shipping: number;
  sales_tax: number;
  merchants_sales_transaction_id: number;
  transaction_id: string;
  is_deleted: boolean;
  line_items: LineItems[];
  [extra: string]: any;
}

export interface LineItems {
  calc_err?: boolean;
  calc_err_message?: string;
  description: string;
  discount: number;
  handling?: number;
  line_item_id: number;
  merchants_sales_transaction_id?: number;
  product_identifier: string;
  product_tax_code: string;
  quantity: number;
  sales_tax: number;
  shipping?: number;
  transaction_date?: string;
  transaction_id: string;
  unit_price: number;
}

export enum SalesTransactionStatus {
  ORDER = 'ORDER',
  REFUND = 'REFUND',
}

export function getDefaultSalesTransactionResponse(): Paginated<SalesTransaction> {
  return new Page<SalesTransaction>();
}
