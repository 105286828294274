import {
  signIn,
  signUp,
  signOut,
  confirmSignIn,
  confirmSignUp,
  resetPassword,
  updatePassword,
  fetchAuthSession,
  resendSignUpCode,
  fetchUserAttributes,
  updateUserAttributes,
  confirmResetPassword,
  confirmUserAttribute,
} from 'aws-amplify/auth';

/**
 * Re-exported to ease testing with jasmine
 */
export default {
  signIn,
  signUp,
  signOut,
  confirmSignIn,
  confirmSignUp,
  resetPassword,
  updatePassword,
  fetchAuthSession,
  resendSignUpCode,
  fetchUserAttributes,
  updateUserAttributes,
  confirmResetPassword,
  confirmUserAttribute,
};

