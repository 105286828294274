import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import { PICKER_DATE_FORMAT, STORE_KEYS } from '@app/utils/constants';
import { SalesTransaction, SalesTransactionStatus } from '@shared/meta-data/sales-transaction';
import { STATES } from '../address-list/address-list-form.interface';
import { StorageService } from '@shared/services/storage/storage.service';
import { SalesTaxConfig, StateConfig } from '@shared/meta-data';
import { BusinessProfile } from '@shared/meta-data/business-profile.meta';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
})
export class TransactionDetailComponent implements OnInit {
  PICKER_DATE_FORMAT = PICKER_DATE_FORMAT;
  salesTransactionStatus = SalesTransactionStatus

  @Input() transaction: SalesTransaction;
  reportingMonth: string
  discount: number;

  constructor(public activeModal: NgbActiveModal, private storage: StorageService) { }

  ngOnInit() {
    let discounts = this.transaction.line_items.map((x) => x?.discount);
    this.discount = discounts.reduce((acc, curr) => acc + curr, 0);
    this.findFilingDate()
  }

  findFilingDate() {
    // Store Data
    const stateAbbr = this.storage.get<BusinessProfile>(STORE_KEYS.BUSINESS_PROFILE_KEY)?.state;
    const taxConfig = this.storage.get<SalesTaxConfig>(STORE_KEYS.SALES_TAX_CONFIG);


    const state = STATES[stateAbbr];
    const config = taxConfig[state] as StateConfig[];

    const transactionDate = moment(this.transaction.transaction_date);

    // Search for a period that the transaction date falls between
    this.reportingMonth = config?.find(res => transactionDate.isBetween(moment(res.start), moment(res.end)))?.title || 'N/A';

  }

}
