<div class="modal-header">
  <h4 class="modal-title">Transaction Details</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
</div>
<div *ngIf="transaction" class="modal-body">
  <div class="d-flex justify-content-between">
    <p>
      <small class="text-muted">ORDER </small>
      <small class="fw-bold">#{{ transaction.transaction_id }}</small>
      <ng-container *ngIf="transaction['transaction_type'] === salesTransactionStatus.REFUND">
         <span class="badge bg-warning text-dark ml-2"  style="line-height: 1;"> refund </span>
      </ng-container>
    </p>
    <p>
      <small class="text-muted">ORDER DATE </small>
      <small class="fw-bold">{{ transaction.transaction_date | date:PICKER_DATE_FORMAT }}</small>
    </p>
    <p>
      <small class="text-muted">REPORTING MONTH </small>
      <small class="fw-bold">{{ reportingMonth }}</small>
    </p>
  </div>

  <div class="card card-body">

    <div class="row">
      <div class="col-6">
        <small class="text-muted">SHIPPED FROM </small>
        <p>
          {{ transaction.from_street }}
          <br>{{ transaction.from_city }}, {{ transaction.from_state }} {{ transaction.from_zip }}
          <br>{{ transaction.from_country }}
        </p>
      </div>
      <div class="col-6">
        <small class="text-muted">SHIPPED TO </small>
        <p>
          {{ transaction.to_street }}
          <br>{{ transaction.to_city }}, {{ transaction.to_state }} {{ transaction.to_zip }}
          <br>{{ transaction.to_country }}
        </p>
      </div>
    </div>

    <table class="table table-hover">
      <thead>
        <tr class="fw-bolder">
          <th scope="col">Item</th>
          <th scope="col">Qty</th>
          <th scope="col">Unit Price</th>
          <th scope="col">Amount</th>
        </tr>
      </thead>
      <tbody>

        <ng-container *ngIf="transaction.line_items">
          <tr *ngFor="let item of transaction.line_items">
            <td>{{item.description}}</td>
            <td>{{item.quantity}}</td>
            <td><app-amount [amount]="item.unit_price"></app-amount></td>
            <td><app-amount [amount]="item.unit_price*item.quantity"></app-amount></td>
          </tr>
        </ng-container>

        <tr class="text-right">
          <td class="text-muted" colspan="3">SHIPPING</td>
          <td>
            <app-amount [amount]="transaction.shipping"></app-amount>
          </td>
        </tr>
        <tr class="text-right">
          <td class="text-muted" colspan="3">HANDLING</td>
          <td>
            <app-amount [amount]="transaction.handling"></app-amount>
          </td>
        </tr>
        <tr class="text-right">
          <td class="text-muted" colspan="3">DISCOUNTS</td>
          <td>
            <app-amount [amount]="discount"></app-amount>
          </td>
        </tr>
        <tr class="text-right">
          <td class="text-muted" colspan="3">ORDER SUBTOTAL</td>
          <td>
            <app-amount [amount]="transaction.amount"></app-amount>
          </td>
        </tr>
        <tr class="text-right">
          <td class="text-muted" colspan="3">SALES TAX</td>
          <td>
            <app-amount [amount]="transaction.sales_tax"></app-amount>
          </td>
        </tr>
        <tr class="fw-bolder text-right">
          <td class="text-muted" colspan="3">ORDER TOTAL</td>
          <td class="fw-bolder">
            <app-amount [amount]="transaction.amount+transaction.sales_tax"></app-amount>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-outline-secondary" ngbAutofocus type="button">Close</button>
</div>
