<div class="page-container">
  <div class="card card-form">
    <div class="row">
      <div class="col-lg-6 card-body text-center">
        <div class="px-3 text-left">
          <h3 class="headings-color">Get started here</h3>
          <p>Building an integration? Get started with <a routerLink="/account-settings/api-access">AtomicTax's API</a>.</p>
        </div>
        <img alt="setup image" class="setup-img p-lg-3 w-75" src="../../../../assets/images/dashboard/setup-img.svg">
      </div>
      <div class="col-lg-6 card-body d-flex align-items-center">
        <form>
          <input (change)="fileChangeListener($event)" id="fileImportInput" type="file" />
          <div class="mb-3 d-flex flex-column">
            <h3>Import your transaction data into AtomicTax</h3>
            <p>
              AtomicTax can import up to 60 days of data during your trial. Once imported, you’ll have full access to
              reports and filing.
            </p>
          </div>
          <a class="btn btn-primary" routerLink="/account-settings/linked-accounts">Connect Integration</a>
          <a [routerLink]="'/upload/sales-transactions'" class="btn btn-outline-secondary">
            Import CSV
            <span class="mdi mdi-plus"></span>
          </a>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header card-header-large bg-white">
          <h4>Included in your AtomicTax Professional plan</h4>
        </div>

        <div class="card-body">
          <div class="alert alert-success d-flex  align-items-center" role="alert">
            <span class="mdi mdi-check-circle bi flex-shrink-0 me-2"></span>
            <div class="text-body"><strong>Accurate real-time sales tax calculations</strong>
              <br>Ensure you're collecting the correct amount, every time
            </div>
          </div>
          <div class="alert alert-success d-flex align-items-center" role="alert">
            <span class="mdi mdi-check-circle bi flex-shrink-0 me-2"></span>
            <div class="text-body"><strong>State sales tax reports</strong>
              <br>View your state reports and determine economic nexus
            </div>
          </div>
          <div class="alert alert-success d-flex  align-items-center" role="alert">
            <span class="mdi mdi-check-circle bi flex-shrink-0 me-2"></span>
            <div class="text-body"><strong>Automated filings</strong>
              <br>Reduce the manual work and let us file for you
            </div>
          </div>
        </div>

        <div class="card-footer text-center border-0">
          <p>Want to learn more? <a href="https://calendly.com/atomickit/sales-tax" target="_blank">Schedule a Professional demo</a>.</p>
        </div>

      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header card-header-large bg-white align-items-center">
          <h4 class="card-header__title flex m-0">Get the most out of your trial</h4>
          <p class=" mb-0">Here are some resources to help you get started.</p>
        </div>
        <div class="card-body">
          <div class="list-group">
            <a href="#" class="list-group-item list-group-item-action text-primary">Invite a teammate</a>
            <a routerLink="/account-settings/state-nexus" class="list-group-item list-group-item-action text-primary">Add state registration details</a>
            <a href="#" class="list-group-item list-group-item-action text-primary">View courses & training</a>
            <a href="https://help.atomictax.com/" target="_blank" class="list-group-item list-group-item-action text-primary">Visit our help center</a>
            <a routerLink="/account-settings/plan/current" class="list-group-item list-group-item-action text-primary">Upgrade your account</a>
          </div>
        </div>
        <div class="card-footer"></div>
      </div>
    </div>
  </div>
</div>
