import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';

import { UtilityService } from '@app/utils/utility.service';
import { DateRangePickerOutput } from '@shared/meta-data';
import { DEFAULT_DATE_FORMAT } from '@app/utils/constants';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomDateParserFormatterService } from './custom-date-parser-formatter.service';


@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangePickerComponent
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatterService
    }
  ]
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {
  hoveredDate: NgbDate | null = null;

  @Input() fromDate: Moment | null;
  @Input() toDate: Moment | null;

  fromDateNgbFormat: NgbDate | null;
  toDateNgbFormat: NgbDate | null;

  @Output() dateSelected: EventEmitter<DateRangePickerOutput> = new EventEmitter();
  touched = false;
  disabled = false;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private utilityService: UtilityService) {
  }

  get value() {
    let fromDate: Moment | string = this.utilityService.NgbDateToMoment(this.fromDateNgbFormat);
    let toDate: Moment | string = this.utilityService.NgbDateToMoment(this.toDateNgbFormat);

    fromDate = fromDate?.isValid() ? fromDate.format(DEFAULT_DATE_FORMAT) : '';
    toDate = toDate?.isValid() ? toDate.format(DEFAULT_DATE_FORMAT) : '';

    return toDate ? `${fromDate} - ${toDate}` : null;
  }

  onChange = _ => {
  };

  onTouched = () => {
  };

  writeValue(interests: any) {
    this.validateInput(interests);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    this.fromDateNgbFormat = this.fromDate ? this.utilityService.MomentToNgbDate(this.fromDate) : null;
    this.toDateNgbFormat = this.toDate ? this.utilityService.MomentToNgbDate(this.toDate) : null;
  }

  onDateSelection(date: NgbDate, datePicker: NgbInputDatepicker) {
    this.markAsTouched();

    if (!this.fromDateNgbFormat && !this.toDateNgbFormat) {
      this.fromDateNgbFormat = date;
    } else if (this.fromDateNgbFormat && !this.toDateNgbFormat && date && (date.after(this.fromDateNgbFormat) || date.equals(this.fromDateNgbFormat))) {
      this.toDateNgbFormat = date;
      datePicker.close()
      this.emitDateEvent();
    } else {
      this.toDateNgbFormat = null;
      this.fromDateNgbFormat = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateNgbFormat && !this.toDateNgbFormat && this.hoveredDate && date.after(this.fromDateNgbFormat) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDateNgbFormat && date.after(this.fromDateNgbFormat) && date.before(this.toDateNgbFormat);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateNgbFormat) ||
      (this.toDateNgbFormat && date.equals(this.toDateNgbFormat)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(input: string) {
    const [from, to] = input.split('-');

    if (!from && !to) {
      return;
    }
    const fromDate = NgbDate.from(this.formatter.parse(from));
    const toDate = NgbDate.from(this.formatter.parse(to));

    if (this.calendar.isValid(fromDate) && this.calendar.isValid(toDate)) {
      this.emitDateEvent(fromDate, toDate);
    }

  }

  emitDateEvent(from = this.fromDateNgbFormat, to = this.toDateNgbFormat) {
    const dateData: DateRangePickerOutput = {
      startDate: this.utilityService.NgbDateToMoment(from),
      endDate: this.utilityService.NgbDateToMoment(to)
    };

    this.dateSelected.emit(dateData);

    // Reactive Forms
    this.onChange(dateData);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
