import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DownloadService } from '@shared/services/download/download.service';
import { finalize, map, } from 'rxjs/operators';

@Directive({
  selector: '[appDownloadFile]'
})
export class DownloadFileDirective {

  @Input() url: string
  @Input() fileName: string

  @Output() loading = new EventEmitter<boolean>()

  constructor(private readonly downloadService: DownloadService) { }

  @HostListener('click')
  onClick(): void {
    this.loading.emit(true);
    this.downloadService.downloadFile(this.url)
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          const contentType = response.headers.get('Content-Type');

          let fileName;

          if (contentDisposition) {
            const contentDispositionParts = contentDisposition.split(';');
            const fileNamePart = contentDispositionParts.find(part => part.trim().startsWith('filename='));

            if (fileNamePart) {
              fileName = fileNamePart.split('=')[1].trim().replace(/['"]/g, '');
            }
          }
       
          return { fileName, file: new Blob([response.body], { type: contentType })};
        }),
        finalize(() => { this.loading.emit(false) })
      )
      .subscribe({
        next: ({ fileName, file }) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(file);
          link.download = fileName ?? 'Downloaded File'
          link.click();
        }
      });
  }
}