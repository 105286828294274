<form [formGroup]="form">
	<h3>Add New Card</h3>

	<div class="row g-3">
		<div class="col-md-12">
			<div class="form-floating">
				<input class="form-control" formControlName="cardNumber" placeholder="" type="text" />
				<label>Card Number</label>
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-floating">
				<input class="form-control" formControlName="cardExpYear" placeholder="" type="text" />
				<label>Exp Year</label>
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-floating">
				<input class="form-control" formControlName="cardExpMonth" placeholder="" type="text" />
				<label>Exp Month</label>
			</div>
		</div>
		<div class="col-md-4">
			<div class="form-floating">
				<input class="form-control" formControlName="cardCVV" placeholder="" type="text" />
				<label>CVV</label>
			</div>
		</div>
		<div class="col-md-12">
			<div class="d-grid gap-2">
				<button (click)="submitForm()" [disabled]="isLoading || form.invalid" class="btn btn-outline-primary">
					Add card
					<i *ngIf="isLoading" aria-hidden="true" class="mdi mdi-cisco-webex mdi-spin mx-1"></i>
				</button>
			</div>
		</div>
	</div>
</form>
