import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(part: number | string, whole: number | string): number | string{
    if(!whole){
      return 'N/A';
    }
    const percentage  = (+part / +whole) * 100;
    return percentage.toFixed(2);
  }
}
