import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogConfig, ConfirmationDialogDefault } from '@shared/meta-data';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  get safeHtml(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.config.body);
  }

  @Input() config: ConfirmationDialogConfig = new ConfirmationDialogDefault()

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}


  proceed(reason = true) {
    this.activeModal.close(reason)
  }
  cancel() {
    this.activeModal.close(false)
  }
}
