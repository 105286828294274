<div class="header d-flex">

    <div class="arrow">
        <button (click)="navigator('previous')" [disabled]="currentItemIsFirstItem || !hasItem" type="button"
            aria-label="Previous item" title="Previous item" class="btn btn-link arrow-btn">
            <span class="navigation-chevron"></span>
        </button>
    </div>

    <div class="title">{{currentItem?.title | titlecase}}</div>

    <div class="arrow right">
        <button (click)="navigator('next')" [disabled]="currentItemIsLastItem || !hasItem || currentItemIsOnlyItem" type="button"
            aria-label="Next item" title="Next item" class="btn btn-link arrow-btn">
            <span class="navigation-chevron"></span>
        </button>
    </div>
</div>