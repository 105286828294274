import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ProcessingProviderBannerComponent } from './processing-provider-banner/processing-provider-banner.component';

@Component({
  standalone: true,
  imports: [ProcessingProviderBannerComponent],
  selector: 'app-information-banners',
  templateUrl: './information-banners.component.html',
})
export class InformationBannersComponent implements AfterViewInit, OnDestroy {
  resizeObserver!: ResizeObserver;

  @ViewChild('banner', { static: false }) banner: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.adjustMainContentMargin();
    });

    this.resizeObserver.observe(this.banner.nativeElement);
  }

  adjustMainContentMargin() {
    const bannerHeight = this.banner.nativeElement.offsetHeight || 0;
    document.documentElement.style.setProperty(
      '--banner-height',
      `${bannerHeight}px`
    );
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.banner.nativeElement);
      this.resizeObserver.disconnect();
    }
  }
}
