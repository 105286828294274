import {
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';


@Component({
  selector: 'app-spinner',
  template: `<div class="preloader d-flex justify-content-center align-items-center" style="background-color: {{backgroundColor}};" *ngIf="isSpinnerVisible">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>`,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {

  @Input() public backgroundColor = 'rgba(11, 19, 43, 0.06)';
  @Input() isSpinnerVisible = true;

}
