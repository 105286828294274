<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"
     [ngClass]="{'show-sidebar': showMobileMenu}"
     [dir]="options.dir"
     [attr.data-theme]="options.theme"
     [attr.data-layout]="options.layout"
     [attr.data-sidebartype]="options.sidebartype"
     [attr.data-sidebar-position]="options.sidebarpos"
     [attr.data-header-position]="options.headerpos"
     [attr.data-boxed-layout]="options.boxed">

  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->

  <header class="topbar"
          [attr.data-navbarbg]="options.navbarbg">
    <nav class="d-flex top-navbar navbar-expand-lg"
         [ngClass]="(options.navbarbg === 'skin6') ? 'navbar-light' : 'navbar-dark'">
      <div class="navbar-header" [ngClass]="(expandLogo)?'expand-logo':''" [attr.data-logobg]="options.logobg">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu"
           class="nav-toggler waves-effect waves-light d-block d-lg-none" href="javascript:void(0)">
          <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand justify-content-center" href="/">
          <!-- Logo icon -->
          <strong class="logo-icon">
            <!-- Dark Logo icon -->
            <img alt="homepage" class="dark-logo" src="assets/images/logo-icon.svg" style="width: 25px;" />
            <!-- Light Logo icon -->
            <img alt="homepage" class="light-logo" src="assets/images/logo-icon.svg" style="width: 25px;" />
          </strong>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
            <!-- dark Logo text -->
            <img alt="homepage" class="dark-logo" src="assets/images/logo-text.svg" style="width: 140px" />
            <!-- Light Logo text -->
            <img alt="homepage" class="light-logo" src="assets/images/logo-text.svg" style="width: 140px" />
          </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)"
           (click)="isCollapsed = !isCollapsed"
           [attr.aria-expanded]="!isCollapsed"
           data-target="#navbarSupportedContent"
           aria-controls="navbarSupportedContent">
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg"
           [ngbCollapse]="!isCollapsed">
        <!-- Horizontal Header -->
        <div *ngIf="options.layout==='horizontal' else vheader" class="w-100">
          <app-horizontal-navigation class="w-100 d-flex align-items-center justify-content-between">
          </app-horizontal-navigation>
        </div>

        <!-- Vertical Header -->
        <ng-template #vheader>
          <div class="w-100">
            <app-nav-bar
              (logOut)="logOut()"
              (navBarToggle)="isCollapsed = !isCollapsed"
              (toggleSidebar)="toggleSidebarType()"
              [user]="userInfo"
              [banner]="authService.appBanner"
            >
            </app-nav-bar>
          </div>

        </ng-template>
      </div>
    </nav>
  </header>

   <!-- Banners-->
   <app-information-banners class="banners"></app-information-banners>

  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside class="left-sidebar"
         [attr.data-sidebarbg]="options.sidebarbg"
         (mouseover)="Logo()"
         (mouseout)="Logo()">
    <!-- Sidebar scroll-->
    <div *ngIf="options.layout==='horizontal'; else vlayout">
      <app-horizontal-sidebar></app-horizontal-sidebar>
    </div>

    <!-- Horizontal Header -->
    <ng-template #vlayout>
      <ng-scrollbar [sensorDebounce]="1" autoHeightDisabled="false" class="scroll-sidebar">
        <app-sidebar (toggleSidebar)="showMobileMenu = !showMobileMenu"></app-sidebar>
      </ng-scrollbar>
    </ng-template>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
	<app-spinner [isSpinnerVisible]="showSpinner$ | async"></app-spinner>

    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <app-breadcrumb></app-breadcrumb>
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
      © 2023 AtomicTax
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>
