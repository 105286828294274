import { BillingProviderEnum, SubscriptionIntervals } from '@shared/meta-data';

export class PaymentInformation {
    amount: number = 0;
    description?: string = 'Please make the payment to start enjoying all the features of our premium plan as soon as possible';
    frequency?: SubscriptionIntervals = 'month';
    imageUrl?: string = 'https://i.imgur.com/S17BrTx.png';
    planName?: string = 'AtomicTax AutoFiling';
    subPlanName?: string = 'Alabama';
    title?: string = 'Subscribe to plan';

    constructor(init?: Partial<PaymentInformation>) {
        Object.assign(this, init || {});
    }
}

export interface PaymentMethod {
    id: string;
    card: {
        brand: 'visa' | string;
        checks: {
            address_line1_check: string;
            address_postal_code_check: string;
            cvc_check: 'pass' | string;
        };
        country: 'US' | string;
        exp_month: number;
        exp_year: number;
        fingerprint: string;
        funding: 'credit' | string;
        generated_from: string;
        last4: string;
        networks: {
            available: ('visa' | string)[];
            preferred: string;
        };
        three_d_secure_usage: {
            supported: true;
        };
        wallet: string;
    };
    type: 'card' | string;
    metadata: {};
}

export interface NewCardForm {
    cardNumber, cardCVV, cardExpMonth, cardExpYear
}

export type PaymentMethodWithToken = PaymentMethod & {
    token?: string,
    use_credits?: boolean,
    provider?: BillingProviderEnum
}
