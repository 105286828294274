import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { CognitoUserInterface } from '@shared/meta-data';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsManagerService {
  acceptedEnvironments: string[] = [];

  private renderer: Renderer2;
  private dripScriptInitialized: boolean = false;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.acceptedEnvironments = ['dev', 'production'];
  }

  private isEnvironmentAccepted(): boolean {
    return this.acceptedEnvironments.includes(environment.NAME);
  }

  async init(): Promise<void> {
    if (!this.isEnvironmentAccepted()) {
      return;
    }

    this.initMixPanel();

    this.loadDripScript().catch(error => {
      this.handleError('Failed to initialize Drip', error);
    });
  }

  sendEvent(eventName: string, properties: object): void {
    if (!this.isEnvironmentAccepted()) {
      return;
    }

    this.sendEventToDrip(eventName, properties).catch(error => {
      this.handleError(`Failed to send event to Drip: ${eventName}`, error);
    });

    this.trackMixPanelEvent(eventName, properties);
  }

  identifyUser(cognitoUser: CognitoUserInterface): void {
    if (!this.isEnvironmentAccepted()) {
      return;
    }

    const { email, given_name } = cognitoUser;
    this.identifyVisitor({ email, given_name }).catch(error => {
      this.handleError('Failed to identify visitor in Drip', error);
    });
    
    this.identifyMixPanelUser({ email, given_name });
  }

  reset(): void {
    if (!this.isEnvironmentAccepted()) {
      return;
    }

    this.resetMixPanel();
  }

  /**
   * Drip
   */

  private async loadDripScript(): Promise<void> {
    if (this.dripScriptInitialized) {
      return;
    }

    return new Promise((resolve, reject) => {
      const script: HTMLScriptElement = this.renderer.createElement('script');
      script.src = `https://tag.getdrip.com/${environment.DRIP_ACCOUNT_ID}.js`;
      script.async = true;
  
      script.onload = () => {
        this.dripScriptInitialized = true;
        resolve();
      };
  
      script.onerror = () => {
        reject(new Error('Failed to load the Drip script'));
      };
  
      this.renderer.appendChild(this.document.head, script);
    });
  }

  private async ensureDripScriptInitialized(): Promise<void> {
    if (!this.dripScriptInitialized) {
      try {
        await this.loadDripScript();
      } catch (error) {
        this.handleError(`Drip script initialization failed: loading drip script`, error);
      }
    }
  }

  private async identifyVisitor({ email, given_name }: { email: string; given_name: string }): Promise<void> {
    await this.ensureDripScriptInitialized();
    window._dcq.push(['identify', { email: email, first_name: given_name, tags: ['Customer'] }]);
  }

  private async sendEventToDrip(eventName: string, properties: object): Promise<void> {
    await this.ensureDripScriptInitialized();
    window._dcq.push(['track', eventName, properties]);
  }

  /**
   * Mix Panel
   */

  private initMixPanel() {
    mixpanel.init(environment.MIX_PANEL_TOKEN, {
      debug: !environment.production,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  private identifyMixPanelUser({ email, given_name }: { email: string; given_name: string }) {
    mixpanel.identify(email);
    mixpanel.people.set({ $email: email, $first_name: given_name });
  }

  private trackMixPanelEvent(eventName: string, properties?: object): void {
    mixpanel.track(eventName, properties);
  }

  private resetMixPanel() {
    mixpanel.reset();
  }

  private handleError(message: string, error: any) {
    Sentry.captureException(new Error(message), {
      extra: { originalError: error }
    });
  }
}
