import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isoDate'
})
export class IsoDatePipe implements PipeTransform {

  transform(value: string, format?: string): string {

    return moment(value, format, true).toISOString() || ''
  }

}
