import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxCsvParser } from 'ngx-csv-parser';
import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import _ from 'lodash';

import { TRANSACTIONS_CSV_HEADERS } from './constants';
import { Data } from '@angular/router';


export class MissingHeadersError extends Error {
  code = 'E_MISSING_HEADERS';
  missingHeaders: string[];

  constructor(missingHeaders: string[]) {
    super('CSV file does not contain valid headers');
    this.missingHeaders = missingHeaders;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  pageInfo: BehaviorSubject<Data> = new BehaviorSubject(Object.create(null))

  get pageInformation(){
    return this.pageInfo.asObservable()
  }

  constructor(
    private ngxCsvParser: NgxCsvParser,
    private NgbDateFormatter: NgbDateParserFormatter
  ) {
  }

  validateTransactionsCSVFile(
    file: File,
    header = false,
    delimiter = ','
  ): Observable<{ file: any; data: any }> {
    return this.ngxCsvParser.parse(file, { header, delimiter })
      .pipe(
        map((parsedResult: Array<any>) => {
          if (parsedResult.length === 0 || parsedResult.length === 1) {
            throw new Error('File does not contain transactions');
          }

          const missingHeaders = _.difference(TRANSACTIONS_CSV_HEADERS, parsedResult[0]);
          if (missingHeaders.length) {
            throw new MissingHeadersError(missingHeaders);
          }

          return { file, data: parsedResult };
        })
      );
  }

  validateFileSize(limitInByte: number, file: File): boolean {
    return file.size <= limitInByte;
  }

  NgbDateToMoment(date: NgbDate): Moment {
    return moment(this.NgbDateFormatter.format(date));
  }

  MomentToNgbDate(date: Moment): NgbDate {
    if (!date) {
      throw new Error('Please Put in a valid date');
    }
    return NgbDate.from({ year: date.year(), month: date.month() + 1, day: date.date() }); // moment months are zero indexed
  }
}
