import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfig } from '@app/app.config';
import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { IResponse } from '@shared/meta-data';
import { STORE_KEYS } from '@app/utils/constants';
import { Params } from '@angular/router';
import { StorageService } from '@shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private storageService: StorageService
  ) {
    this.handleError = httpErrorHandler.createHandleError('Reference Data');
  }


  fetchReferenceData(): Observable<Params> {
    return this.httpClient
      .get<IResponse<any>>(`${AppConfig.API_URL}/reference-data`)
      .pipe(map(({ content: { providers } }) => {
        this.storageService.save(STORE_KEYS.TRANSACTION_PROVIDERS, providers)
        return providers
      }), catchError(this.handleError('Reference Data', null)));
  }

  retrieveReferenceData(): Array<string> {
    return Object.values(this.storageService.get(STORE_KEYS.TRANSACTION_PROVIDERS) || {})
  }
}
