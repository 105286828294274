import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';

const getLinkedAccountState = (state: fromApp.AppState) => state.linkedAccount;

export const getIsLoading = createSelector(
  getLinkedAccountState,
  (state) => state.isLoading
);

export const getLinkedAccounts = createSelector(
  getLinkedAccountState,
  (state) => state.linkedAccounts
);

export const getLinkedAccountsEvents = createSelector(
  getLinkedAccountState,
  (state) => state.linkedAccountEvents
);

export const getNewEventsProcessed = createSelector(
  getLinkedAccountState,
  (state) => state.newEventsWhereProcessed
);

export const getIsSyncComplete = createSelector(
  getLinkedAccountState,
  (state) => state.isSyncComplete
);

export const getTotalTransactionsProcessed = createSelector(
  getLinkedAccountState,
  (state) => state.totalProcessedTransactions
);

export const getLinkedAccountsWithEventMap = createSelector(
  getLinkedAccountState,
  (state) => state.linkedAccountsWithEventMap
);