import { StateCode } from '@shared/components/address-list/address-list-form.interface';
import { BillingProvider } from './subscription.meta';
import { PAYMENT_SCHEDULES } from '@app/utils/constants';

export interface StateFilingData {
  [key: string]: string;
}

export interface AutoFilingCreatePayload {
  state: StateCode;
  frequency: PAYMENT_SCHEDULES;
  filing_json: string;
}

export interface AutoFilingUpdatePayload
  extends AutoFilingCreatePayload,
    Pick<
      AutoFiling,
      'business_profile_id' | 'merchant_state_filing_id' | 'state' | 'status'
    > {}

export interface AutoFilingCancelPayload
  extends Pick<AutoFilingCreatePayload, 'state'> {}

export interface AutoFiling {
  business_profile_id: number;
  created_at: string;
  filing_data: StateFilingData;
  frequency: PAYMENT_SCHEDULES;
  merchant_state_filing_id: number;
  state: StateCode;
  status: AUTO_FILING_STATUSES;
  updated_at: string;
}

export enum AUTO_FILING_STATUSES {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  REGISTERED = 'REGISTERED',
  PROCESSING = 'PROCESSING',
  ACTIVE = 'ACTIVE',
}

export interface AutoFilingPayment {
  price: string;
  merchant_state_filing_id: number;
  token?: string;
  payment_method_id?: string;
  provider: BillingProvider;
  use_credits?: boolean;
}

export interface AutoFilingPipeIds {
  Filing: {
    [key in StateCode]: string;
  };
}

export interface AutoFilingPipeConfig {
  pipes: Array<AutoFilingStateConfig>;
}

export interface AutoFilingStateConfig {
  uuid: string;
  description: string;
  emailAddress: string;
  id: string;
  name: string;
  organizationId: string;
  start_form_fields: Array<AutoFilingStateFormField>;
}

export interface AutoFilingStateFormField {
  uuid: string;
  description: string;
  id: string;
  required: boolean;
  label: string;
  type: string;
  index: number;
}
