const {
  Products
} = require('../app-constants/products');
const {
  US_STATES
} = require('../app-constants/states');
const autoFilingPipeIds = {
  [Products.Filing]: {
    // point to SupportedStates[] values
    [US_STATES.Alabama]: '303474399',
    [US_STATES.Alaska]: '303474413',
    [US_STATES.Arizona]: '303476972',
    [US_STATES.Arkansas]: '303476975',
    [US_STATES.California]: '303476977',
    [US_STATES.Colorado]: '303476979',
    [US_STATES.Connecticut]: '303476981',
    [US_STATES.Delaware]: '303476982',
    [US_STATES['District of Columbia']]: '303476983',
    [US_STATES.Florida]: '303476984',
    [US_STATES.Georgia]: '303477052',
    [US_STATES.Hawaii]: '303477057',
    [US_STATES.Idaho]: '303477060',
    [US_STATES.Illinois]: '303477061',
    [US_STATES.Indiana]: '303477062',
    [US_STATES.Iowa]: '303477063',
    [US_STATES.Kansas]: '303477064',
    [US_STATES.Kentucky]: '303477070',
    [US_STATES.Louisiana]: '303477084',
    [US_STATES.Maine]: '303477093',
    [US_STATES.Maryland]: '303477102',
    [US_STATES.Massachusetts]: '303477115',
    [US_STATES.Michigan]: '303477126',
    [US_STATES.Minnesota]: '303477127',
    [US_STATES.Mississippi]: '303477134',
    [US_STATES.Missouri]: '303477889',
    [US_STATES.Montana]: '303467713',
    [US_STATES.Nebraska]: '301158188',
    [US_STATES.Nevada]: '303477976',
    [US_STATES['New Hampshire']]: '303477980',
    [US_STATES['New Jersey']]: '303478051',
    [US_STATES['New Mexico']]: '303478418',
    [US_STATES['New York']]: '303478423',
    [US_STATES['North Carolina']]: '303478438',
    [US_STATES['North Dakota']]: '303478447',
    [US_STATES.Ohio]: '303478449',
    [US_STATES.Oklahoma]: '303478467',
    [US_STATES.Oregon]: '303478513',
    [US_STATES.Pennsylvania]: '303478527',
    // No Puerto Rico in document
    [US_STATES['Puerto Rico']]: null,
    [US_STATES['Rhode Island']]: '303478539',
    [US_STATES['South Carolina']]: '303478551',
    [US_STATES['South Dakota']]: '303478578',
    [US_STATES.Tennessee]: '303478595',
    [US_STATES.Texas]: '303468183',
    [US_STATES.Utah]: '303478611',
    [US_STATES.Vermont]: '303478629',
    [US_STATES.Virginia]: '303478641',
    [US_STATES.Washington]: '303478648',
    [US_STATES['West Virginia']]: '303478658',
    [US_STATES.Wisconsin]: '303478710',
    [US_STATES.Wyoming]: '303478728'
  }
};
module.exports = autoFilingPipeIds;