import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
})
export class StepperComponent {
  @Input() currentStep: number;
  @Input() canJump = false;
  /**
   * The Default value is used by Onboarding Component
   */
  @Input() numberOfSteps = environment.FEATURE_FLAG.teammates_signup ? 5 : 4;
  @Output() currentStepChange: EventEmitter<number> = new EventEmitter<number>();
}
