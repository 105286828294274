import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TextTruncatePipe implements PipeTransform {
  transform(text: string | null | undefined, length: number): string {
    if (!text) {
      return '';
    }

    if (text.length > length) {
      return text.substring(0, length) + '...';
    }

    return text;
  }
}
