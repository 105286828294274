import { Component } from '@angular/core';
import { FullComponent } from '@app/layouts/full/full.component';
import { SideBarType } from '@shared/meta-data/sidebar.meta';

@Component({
  selector: 'app-demo',
  templateUrl: '../full/full.component.html',
})
export class DemoComponent extends FullComponent {

  /**
   * Override config for FullComponent
   */
  options: { sidebartype: SideBarType, [key: string]: any } = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // two possible values: vertical, horizontal
    sidebartype: SideBarType.MINI_SIDE_BAR, // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

}
